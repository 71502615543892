import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireDatabase } from 'angularfire2/database';
import { database } from 'firebase';

import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataproviderService } from '../provider/dataprovider.service';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.less']
})
export class EmailComponent implements OnInit {

  form: FormGroup;
  mail:any;
  selectVal;
  disabled = false;

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public afDatabase: AngularFireDatabase,
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    public toasterService: ToasterService,
    public dataProvider: DataproviderService,
    public activeRoute: ActivatedRoute,
    public auth: AuthService,
  ) { 

  }

  ngOnInit() {
    this.spinnerService.show();
    this.createForm().then(()=>{
      this.activeRoute.params.subscribe(param => {
        console.log(param);
        if(param.type){
          this.disabled = true;
          this.dataProvider.getMailDetail(param).then((mail) => {
            this.mail = mail;
            console.log(mail);
            this.form.patchValue({
              title: this.mail.title,
              message: this.mail.message,
              type: this.mail.type,
            });
            this.form.get('type').disable();
            this.spinnerService.hide();
          }).catch((err) => {
            console.log('err in email detail: ', err);
            this.spinnerService.hide();
          });
        } else {
          this.spinnerService.hide();
        }
        console.log(this.disabled);
      }, error => {
        console.log(error);
      });
    });
  }

  /**
   * createForm() creates a Form
   * @returns true if no error occured
   */
  createForm(){
    return new Promise<any>((resolve, reject) => {
      this.form = new FormGroup({
        title: new FormControl(),
        message: new FormControl(),
        type: new FormControl(),
      });
      this.form = this.fb.group({
        title: ['', Validators.required],
        message: ['', Validators.required],
        type: [Validators.required],
      });  
      resolve();    
    });
  }

  /**
   * onChange(newVal) reacts to the select field in frontend
   * @param newVal - the selected Value of the select Field
   */
  onChange(newVal){
    console.log(newVal);
    this.selectVal = newVal;
  }

  /**
   * saveMail() saves an email message in db
   */
  saveMail(){    
    this.spinnerService.show();
    if(!this.mail){
      let { title, message, type } = this.form.value;
      console.log('should be update?: ', type);
      console.log(type);
      if(!title || title == '' || !message || message == '' || !type){
        let toast: Toast = {
          type: 'info',
          title: 'Bitte füllen Sie alle Felder aus.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
        this.spinnerService.hide();
      } else {
        console.log('saveMail updating with type: ', type);
        let mailObject = Object();
        mailObject.title = title;
        mailObject.message = message;
        mailObject.type = type;
        this.dataProvider.saveMail(mailObject, type).then(() => {
          let toast: Toast = {
            type: 'info',
            title: 'Vorlage gespeichert.',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.form.reset();
        });
        this.spinnerService.hide();
      }
    } else {
      let { title, message, type } = this.form.value;
      if(!title || title == '' || !message || message == ''){        
        this.spinnerService.hide();
        let toast: Toast = {
          type: 'info',
          title: 'Bitte füllen Sie alle Felder aus.', 
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      } else {
        let mailObject = Object();
        mailObject.title = title;
        mailObject.message = message;
        mailObject.type = this.mail.type;
        this.dataProvider.updateMail(mailObject).then(() => {
          let toast: Toast = {
            type: 'info',
            title: 'Vorlage geändert.',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.form.reset();
        });
        this.spinnerService.hide();
      }
    }
  }

}
