import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AngularFireDatabase } from 'angularfire2/database';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { UserProviderService } from 'src/app/providers/user-provider.service';
import { DataproviderService } from '../provider/dataprovider.service';
import { AngularFireStorage } from 'angularfire2/storage';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Toast, ToasterConfig, ToasterService } from '../../../../node_modules/angular2-toaster';

import { getOrCreateNodeInjector } from '@angular/core/src/render3/di';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';

declare var google: any;


@Component({
  selector: 'app-bewerber-detail',
  templateUrl: './bewerber-detail.component.html',
  styleUrls: ['./bewerber-detail.component.less']
})
export class BewerberDetailComponent implements OnInit {

  form: FormGroup;
  testForm: FormGroup;
  userId;
  user;
  downloadURL: string;
  link: Observable<string>;
  awards;
  jobxp;
  docs;
  hobbies;
  skills;
  schule;
  qualis;
  languages;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 2,
    animation: 'slideUp'
  });

  constructor(
    private fb: FormBuilder,
    public activeRoute: ActivatedRoute,
    public dataProvider: DataproviderService,
    public userProvider: UserProviderService,
    public spinnerService: Ng4LoadingSpinnerService,
    public auth: AuthService,
    public storage: AngularFireStorage,
    public afDatabase: AngularFireDatabase,
    public toasterService: ToasterService,
    private gMapsApiLoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.createForm().then(() => {
      this.activeRoute.params.subscribe(param => {
        console.log(param)
        this.userId = param.userId;
        if (this.userId != "" && this.userId) {
          this.spinnerService.show();
          // get user with id -> then bla bla
          this.dataProvider.getBewerberData(this.userId).then((user: any) => {
            this.user = user;
            if (user.awards && user.awards != '') {
              let keys = Object.keys(user.awards);
              console.log('got your keys: ', keys);
              let awards = new Array();
              keys.forEach(id => {
                awards.push(user.awards[id]);
              });
              this.awards = awards;
              console.log('this.awards: ', this.awards);
            }
            if (user.jobxp && user.jobxp != '') {
              let keys = Object.keys(user.jobxp);
              console.log('got your keys: ', keys);
              let jobxp = new Array();
              keys.forEach(id => {
                jobxp.push(user.jobxp[id]);
              });
              this.jobxp = jobxp;
            }
            if (user.docs && user.docs != '') {
              let keys = Object.keys(user.docs);
              console.log('got your keys: ', keys);
              let docs = new Array();
              keys.forEach(id => {
                docs.push(user.docs[id]);
              });
              this.docs = docs;
            }
            if (user.hobbies && user.hobbies != '') {
              let keys = Object.keys(user.hobbies);
              console.log('got your keys: ', keys);
              let hobbies = new Array();
              keys.forEach(id => {
                hobbies.push(user.hobbies[id]);
              });
              this.hobbies = hobbies;
            }
            if (user.skills && user.skills != '') {
              let keys = Object.keys(user.skills);
              console.log('got your keys: ', keys);
              let skills = new Array();
              keys.forEach(id => {
                skills.push(user.skills[id]);
              });
              this.skills = skills;
            }
            if (user.qualis && user.qualis != '') {
              let keys = Object.keys(user.qualis);
              console.log('got your keys: ', keys);
              let qualis = new Array();
              keys.forEach(id => {
                qualis.push(user.qualis[id]);
              });
              this.qualis = qualis;
            }
            if (user.schule && user.schule != '') {
              let keys = Object.keys(user.schule);
              console.log('got your keys: ', keys);
              let schule = new Array();
              keys.forEach(id => {
                schule.push(user.schule[id]);
              });
              this.schule = schule;
            }
            if (user.languages && user.languages != '') {
              let keys = Object.keys(user.languages);
              console.log('got your keys: ', keys);
              let languages = new Array();
              keys.forEach(id => {
                languages.push(user.languages[id]);
              });
              this.languages = languages;
            }
            this.form = this.fb.group({
              adress: user.adress,
              birthday: user.birthday,
              email: user.email,
              nachname: user.nachname,
              ort: user.ort,
              phone: user.phone,
              plz: user.plz,
              slogan: user.slogan,
              vorname: user.vorname,
            });
            this.spinnerService.hide();
          }).catch((error) => {
            console.log('error: ', error);
            this.spinnerService.hide();
          });
          this.spinnerService.hide();
        }
      });
    });
  }

  /** 
  * createForm() creates a Form in frontend
  * @returns a resolved Promise that triggers further onInit actions 
  */
  createForm() {
    return new Promise((resolve, reject) => {
      this.form = new FormGroup({
        adress: new FormControl(),
        birthday: new FormControl(),
        email: new FormControl(),
        nachname: new FormControl(),
        ort: new FormControl(),
        phone: new FormControl(),
        plz: new FormControl(),
        slogan: new FormControl(),
        vorname: new FormControl(),
      });
      this.form = this.fb.group({
        adress: ['', Validators.required],
        birthday: ['', Validators.required],
        email: ['', Validators.required],
        nachname: ['', Validators.required],
        ort: ['', Validators.required],
        phone: ['', Validators.required],
        plz: ['', Validators.required],
        slogan: ['', Validators.required],
        vorname: ['', Validators.required],
      });
      resolve();
    });
  }

  /**
   * getLatLng(address) gets the latLng of the specified address
   * @param address the users address (PLZ, city, street)
   * @returns latitude and longitude of the given address
   */
  getLatLng(address) {
    return new Promise((resolve, reject) => {
      this.gMapsApiLoader.load().then(() => {
        let geocode = new google.maps.Geocoder();
        geocode.geocode({ 'address': address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log('Success - ', results);
            //get lat lng from results and resolve it
            let lat = results[0].geometry.location.lat();
            let lng = results[0].geometry.location.lng();
            resolve({ lat, lng });
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            resolve(null);
          }
        });
      });
    });
  }

  /**
   * recreates the Frontend Form with new Data
   */
  recreateFormData() {
    this.dataProvider.getBewerberData(this.userId).then((user: any) => {
      this.user = user;
      this.form = this.fb.group({
        adress: user.adress,
        birthday: user.birthday,
        email: user.email,
        nachname: user.nachname,
        ort: user.ort,
        phone: user.phone,
        plz: user.plz,
        slogan: user.slogan,
        vorname: user.vorname,
      });
      this.spinnerService.hide();
    });
  }

  /**
   * resetPassword() - resets the Password of the user
   */
  resetPassword(){
    this.spinnerService.show();
    if(!this.user.email){
      let toast: Toast = {
        type: 'info',
        title: 'Bitte geben sie eine E-Mail-Adresse ein.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    } else {
      this.auth.resetPassword(this.user.email).then(()=>{
        this.spinnerService.hide();
      });
    } 
  }

  /**
   * updateData() triggers the updateData function in dataProvider
   * with the created updateObject 
   */
  updateData() {
    this.spinnerService.show();
    let { adress, birthday, email, nachname, ort,
      phone, plz, slogan, vorname }
      = this.form.value;
    let updateObject: any = {};

    if (adress && (this.user.adress != adress)) {
      updateObject.adress = adress;
    }
    if (birthday && (this.user.birthday != birthday)) {
      updateObject.birthday = birthday;
    }
    if (email && (this.user.email != email)) {
      updateObject.email = email;
    }
    if (nachname && (this.user.nachname != nachname)) {
      updateObject.nachname = nachname;
    }
    if (ort && (this.user.ort != ort)) {
      updateObject.ort = ort;
    }
    if (phone && (this.user.phone != phone)) {
      updateObject.phone = phone;
    }
    if (plz && (this.user.plz != plz)) {
      updateObject.plz = plz;
    }
    if (slogan && (this.user.slogan != slogan)) {
      updateObject.slogan = slogan
    }
    if (vorname && (this.user.vorname != vorname)) {
      updateObject.vorname = vorname
    }
    if (!this.form.valid) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
    else if (updateObject && Object.keys(updateObject).length != 0) {
      if (updateObject.plz || updateObject.ort || updateObject.country) {
        let country = "Deutschland";
        this.getLatLng(plz + " " + ort + ", " + country).then((response: { lat: string, lng: string }) => {
          updateObject.lat = response.lat;
          updateObject.lng = response.lng;
          this.dataProvider.updateBewerber(updateObject, this.userId).then(() => {
            let toast: Toast = {
              type: 'success',
              title: 'Jobcode erfolgreich geändert!',
              showCloseButton: true
            }
            this.toasterService.pop(toast);
            this.spinnerService.hide();
            this.recreateFormData();
          }).catch((err) => {
            console.log(err);
          });
        }).catch((err) => {
          console.log(err);
        });
      } else {
        this.dataProvider.updateBewerber(updateObject, this.userId).then(() => {
          let toast: Toast = {
            type: 'success',
            title: 'Jobcode erfolgreich geändert!',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.recreateFormData();
          this.spinnerService.hide();
        }).catch((err) => {
          console.log(err);
        });
      }
    }
    else if (Object.keys(updateObject).length == 0) {
      let toast: Toast = {
        type: 'info',
        title: 'Sie haben keine Änderungen vorgenommen.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
  }

  /**
   * uploadImage(event) - triggers the upload of the img file selected
   * @param event the data selected(png or img)
   * @returns true when the download Url of the image is ready
   */
  uploadImage(event) {
    this.spinnerService.show();
    return new Promise((resolve, reject) => {
      let file = event.target.files[0];
      console.log(file);
      let filePath = 'bewerber/' + this.user.userId + '/images/profil';
      let fileRef = this.storage.ref(filePath);
      let task = this.storage.upload(filePath, file);
      task.snapshotChanges().pipe(
        finalize(() => {
          (this.link = fileRef.getDownloadURL()),
            resolve();
        })
      ).subscribe();
    });
  }

  /**
   * uploadFile(event) - uploads an imageUrl to the db after uploadImage() uploads the image to storage
   * @param event the data selected by the user
   */
  uploadFile(event) {
    this.uploadImage(event).then(() => {
      this.link.subscribe(link => {
        this.downloadURL = link;
        let userRef = this.afDatabase.database.ref("/users/bewerber/" + this.user.userId).child('profilpic');
        let linkRef = userRef.child('imageUrl');
        linkRef.once("value", snapshot => {
          linkRef.set(this.downloadURL);
          let toast: Toast = {
            type: 'success',
            title: 'Logo erfolgreich hochgeladen',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.spinnerService.hide();
        });
      });
    });
  }

}


