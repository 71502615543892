import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Router, NavigationExtras } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from '../../providers/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-bewerbungen',
  templateUrl: './bewerbungen.component.html',
  styleUrls: ['./bewerbungen.component.less']
})
export class BewerbungenComponent implements OnInit {

  applications: any;
  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public userProvider: UserProviderService,
    public auth: AuthService,
    private spinnerService: Ng4LoadingSpinnerService,
    public router: Router,
    public toasterService: ToasterService,
  ) {
    this.getData();
  }

  ngOnInit() {
    console.log("bewerbungen loaded")
  }

  deleteApplication(application, index) {
    console.log("application: " + application);
    console.log("index: " + index);
    if (window.confirm('Application wirklich löschen?')) {
      this.userProvider.deleteApplicationStorage(application).then(() => {
        this.userProvider.deleteApplication(application).then(() => {
          this.applications.splice(index, 1);
          console.log("Application gelöscht");
          let toast: Toast = {
            type: 'success',
            title: 'Bewerbung erfolgreich gelöscht!',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  getData() {
    this.getApplications();
    this.spinnerService.show();
  }

  getApplications() {
    console.log('get apps fired!');
    this.userProvider.getApplications().then((applications: any) => {
      this.applications = applications;
      console.log(this.applications);
      this.spinnerService.hide();
    }).catch((err) => {
      console.log('err in get appl.: ', err);
    });
  }

  setRead(application) {
    this.userProvider.applicationNavData = application;
    this.userProvider.setRead(application).then(() => {
      this.router.navigate(['bewerbungen-details']);
    }).catch((err) => {
      console.log(err);
    });
  }

}
