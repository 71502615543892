import { AngularFireStorage } from 'angularfire2/storage';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireDatabase } from 'angularfire2/database';
import { database, app } from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase';
import { AuthService } from './auth.service';

@Injectable()
export class UserProviderService {

  public user: any = null;
  public job: any;
  public applications: any;
  public unreadCount: number;
  public readCount: number;
  public applicationNavData: any;

  constructor(
    public afDatabase: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public router: Router,
    public storage: AngularFireStorage,
    public authService: AuthService,
  ) { }

  deleteApplication(application) {
    return new Promise((resolve, reject) => {
      let applicationRef = this.afDatabase.database.ref('applications').child(application.userId).child(application.pushId);
      applicationRef.remove().then(() => {
        this.updateCounts(application).then(() => {
          resolve();
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  deleteApplicationStorage(application) {
    return new Promise((resolve, reject) => {
      console.log('application in userProvider deleteApplication(): ', application);
      let vidKeys = Object.keys(application.questionsStatus);
      let re = new RegExp('q[1-5]');
      vidKeys.forEach(videoUrl => {
        if (videoUrl.match(re)) {
          console.log('found video match on regex: ', videoUrl);
        } else {
          if (typeof (application.questionsStatus[videoUrl]) === "string" && application.questionsStatus[videoUrl] != '') {
            console.log(videoUrl);
            console.log('there are strings here...: ', application.questionsStatus[videoUrl]);
            let vidUrl = this.storage.storage.refFromURL(application.questionsStatus[videoUrl]);
            if (vidUrl != null) {
              console.log('not null vidUrl, ', vidUrl);
              vidUrl.delete().catch((err) => {
                if (err.code == 'storage/object-not-found') {
                  console.log(err);
                  resolve();
                }
              });
            }
          }
        }
      }, error => {
        reject(error);
      });
      resolve();
    });
  }

  deleteComment(application, item) {
    return new Promise((resolve, reject) => {
      let applicationRef = this.afDatabase.database.ref('applications').child(application.userId).child(application.pushId).child('comments');
      applicationRef.once('value', snap => {
        let comments = snap.val();
        if (comments && comments.pushId == item.pushID) {
          applicationRef.child(item.pushId).remove().then(() => {
            resolve();
          }).catch((err) => {
            reject(err);
          });
        } else {
          reject('no comments found');
        }
      });
    });
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      this.authService.checkUserState().then((res) => {
        if (res) {
          console.log('2nd level user');
          let userId = this.authService.bankId;
          this.afDatabase.database.ref("users/banken/").child(userId).once("value", snapshot => {
            //snapshot of current users data
            this.user = snapshot.val();
            console.log('user in getUser: ', this.user);
            resolve(this.user);
          }, error => {
            console.log(error);
          });
        } else {
          this.afAuth.authState.subscribe((auth) => {
            if (auth != null) {
              let userId = this.afAuth.auth.currentUser.uid; //id of current user
              this.afDatabase.database.ref("users/banken/").child(userId).once("value", snapshot => {
                //snapshot of current users data
                this.user = snapshot.val();
                resolve(this.user);
              }, error => {
                console.log(error);
              });
            }
          });
        }
      });

    });
  }

  getUserUpdateData() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(
        (auth) => {
          if (auth != null) {
            let userId = this.afAuth.auth.currentUser.uid; //id of current user

            this.afDatabase.database.ref("users/banken/").child(userId).once("value", snapshot => {
              //snapshot of current users data
              this.user = snapshot.val();
              resolve(this.user);
            }, error => {
              reject(error);
              console.log(error);
            });
          }
        }
      );
    });
  }

  getApplications() {
    return new Promise((resolve, reject) => {
      this.authService.checkUserState().then((res) => {
        if (res) {
          console.log('2nd level user');
          let userId = this.authService.bankId;
          let applicationRef = this.afDatabase.database.ref('applications'); // does not work: .orderByChild('companyId').equalTo(userId)
          console.log('db query. userId: ', userId);
          applicationRef.once("value", snapshot => {
            let allApplication = snapshot.val();
            console.log(allApplication);
            if (allApplication) {
              let keys = Object.keys(allApplication);
              let temp = new Array<any>();
              this.unreadCount = 0;
              this.readCount = 0;
              keys.forEach(userKeys => {
                let applicationKeys = Object.keys(allApplication[userKeys]);
                applicationKeys.forEach(application => {
                  if (allApplication[userKeys][application].companyId == userId && allApplication[userKeys][application].sendMail) {
                    temp.push(allApplication[userKeys][application]);
                    if (!allApplication[userKeys][application].isRead) {
                      this.unreadCount++;
                    }
                    if (allApplication[userKeys][application].isRead) {
                      this.readCount++;
                    }
                  }
                });
              });
              temp.sort((val1, val2) => {
                return val2.sendDate - val1.sendDate;
              });
              this.applications = temp;
              console.log('applicationArray in get Applications for Company: ', temp);
              resolve(temp);
            }
          }).catch((err) => {
            console.log('err in get app userp: ', err);
            reject(err);
          });
        } else {
          this.afAuth.authState.subscribe(auth => {
            console.log('received auth: ', auth);
            if (auth != null) {
              let userId = auth.uid
              let applicationRef = this.afDatabase.database.ref('applications'); // does not work: .orderByChild('companyId').equalTo(userId)
              console.log('db query. userId: ', userId);
              applicationRef.once("value", snapshot => {
                let allApplication = snapshot.val();
                console.log(allApplication);
                if (allApplication) {
                  let keys = Object.keys(allApplication);
                  let temp = new Array<any>();
                  this.unreadCount = 0;
                  this.readCount = 0;
                  keys.forEach(userKeys => {
                    let applicationKeys = Object.keys(allApplication[userKeys]);
                    applicationKeys.forEach(application => {
                      if (allApplication[userKeys][application].companyId == userId && allApplication[userKeys][application].sendMail) {
                        temp.push(allApplication[userKeys][application]);
                        if (!allApplication[userKeys][application].isRead) {
                          this.unreadCount++;
                        }
                        if (allApplication[userKeys][application].isRead) {
                          this.readCount++;
                        }
                      }
                    });
                  });
                  temp.sort((val1, val2) => {
                    return val2.sendDate - val1.sendDate;
                  });
                  this.applications = temp;
                  console.log('applicationArray in get Applications for Company: ', temp);
                  resolve(temp);
                }
              }).catch((err) => {
                console.log('err in get app userp: ', err);
                reject(err);
              });
            }
          });
        }
      });
      // this.afAuth.authState.subscribe(auth => {
      //   console.log('received auth: ', auth);
      //   if (auth != null) {
      //     let userId;
      //     if (this.authService.adminUser) {
      //       userId = this.authService.currentBank.userId;
      //     } else {
      //       userId = auth.uid
      //     }
      //     let applicationRef = this.afDatabase.database.ref('applications'); // does not work: .orderByChild('companyId').equalTo(userId)
      //     console.log('db query. userId: ', userId);
      //     applicationRef.once("value", snapshot => {
      //       let allApplication = snapshot.val();
      //       console.log(allApplication);
      //       if (allApplication) {
      //         let keys = Object.keys(allApplication);
      //         let temp = new Array<any>();
      //         this.unreadCount = 0;
      //         this.readCount = 0;
      //         keys.forEach(userKeys => {
      //           let applicationKeys = Object.keys(allApplication[userKeys]);
      //           applicationKeys.forEach(application => {
      //             if (allApplication[userKeys][application].companyId == userId && allApplication[userKeys][application].sendMail) {
      //               temp.push(allApplication[userKeys][application]);
      //               if (!allApplication[userKeys][application].isRead) {
      //                 this.unreadCount++;
      //               }
      //               if (allApplication[userKeys][application].isRead) {
      //                 this.readCount++;
      //               }
      //             }
      //           });
      //         });
      //         temp.sort((val1, val2) => {
      //           return val2.sendDate - val1.sendDate;
      //         });
      //         this.applications = temp;
      //         console.log('applicationArray in get Applications for Company: ', temp);
      //         resolve(temp);
      //       }
      //     }).catch((err) => {
      //       console.log('err in get app userp: ', err);
      //       reject(err);
      //     });
      //   }
      // });
    });
  }

  getApplicationWithId(pushId: any, userId: any) {
    return new Promise((resolve, reject) => {
      if (this.authService.adminUser) {
        userId = this.authService.currentBank.userId;
      }
      let applicationRef = this.afDatabase.database.ref("/applications/").child(userId).child(pushId);
      applicationRef.once("value", snapshot => {
        let application = snapshot.val();
        resolve(application);
      }, error => {
        console.log(error);
        reject(error);
      });
    });
  }

  setRead(application) {
    return new Promise((resolve, reject) => {
      this.afDatabase.database.ref('applications').child(application.userId).child(application.pushId).update({ isRead: true }).then(() => {
        resolve();
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  updateCounts(application) {
    // Bewerbung löschen ->  job.bewerbungen -1 | bewerber.applicationCount -1 | bank.statistics.applications
    console.log('updateCount(), application: ', application);
    return new Promise((resolve, reject) => {
      let jobCountRef = this.afDatabase.database.ref('jobs').child(application.companyId).child(application.jobId).child('bewerbungen');
      jobCountRef.once('value', snap => {
        let count = snap.val();
        if (count) {
          console.log(count);
          count -= 1;
          jobCountRef.parent.update({ bewerbungen: count });
        }
      }).then(() => {
        let appCountRef = this.afDatabase.database.ref('users').child('bewerber').child(application.userId).child('applicationCount');
        appCountRef.once('value', snap => {
          let count = snap.val();
          if (count) {
            console.log('applicationcount: ', count);
            count -= 1;
            appCountRef.parent.update({ applicationCount: count });
          }
        }).then(() => {
          let companyRef = this.afDatabase.database.ref('users').child('banken').child(application.companyId).child('statistic');
          companyRef.once("value", snap => {
            let company = snap.val();
            if (company) {
              console.log('statisik = ', company);
              let count = company.applications;
              if (count) {
                count -= 1;
                companyRef.update({ applications: count }).then(() => {
                  console.log('everything updated')
                  resolve();
                }).catch((err) => {
                  console.log('err in bankappcount update_ ', err);
                  reject(err);
                });
              }
            }
          }).catch((err) => {
            console.log('err in bankappcount update_ ', err);
            reject(err);
          });
        }).catch((err) => {
          console.log('err in bankappcount update_ ', err);
          reject(err);
        });
      }).catch((err) => {
        console.log('err in bankappcount update_ ', err);
        reject(err);
      });
    });
  }

  updateData(dataObject: any) {
    // dataObject: Values from Setting inputs    
    this.afAuth.authState.subscribe(
      (auth) => {
        if (auth != null) {
          let userId;
          if (this.authService.adminUser) {
            userId = this.authService.currentBank.userId;
          } else {
            userId = this.afAuth.auth.currentUser.uid;
          }
          const user = this.afDatabase.object("/users/" + userId);
          user.update(dataObject);
        }
      });
    this.router.navigateByUrl('/dashboard');
  }

  logout() {
    this.afAuth.auth.signOut();
    this.router.navigate(['/']);
  }
}
