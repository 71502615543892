import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase } from 'angularfire2/database';
import { AuthService } from '../../providers/auth.service';

import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-createbank',
  templateUrl: './createbank.component.html',
  styleUrls: ['./createbank.component.less']
})
export class CreatebankComponent implements OnInit {

  company: string;
  partner: string;
  phone: string;
  adress: string;
  plz: string;
  city: string;
  country: string;
  website: string;
  applications: string;
  balance: string;
  email: string;
  password: string;
  accept: boolean;
  inactiveJobs;
  activeJobs;
  jobCount;

  private toasterService;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(public afAuth: AngularFireAuth, public afDatabase: AngularFireDatabase, toasterService: ToasterService,
    private router: Router, private spinner: Ng4LoadingSpinnerService, public auth: AuthService) {
    this.toasterService = toasterService;
  }

  ngOnInit() {
  }

  /**
   * validateEmail(email) - validates the email
   * @param email the email to validate
   */
  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * register() registers a user and saves the given values in DB
   */
  register() {
    this.spinner.show();
    //check if all fields are filled
    if (!this.company || !this.partner || !this.phone || !this.adress || !this.plz || !this.city || !this.country || !this.email || !this.password) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Pflichtfelder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinner.hide();
      //all fields are filled 
    } else {
      // validate email
      if (!this.validateEmail(this.email)) {
        let toast: Toast = {
          type: 'info',
          title: 'Bitte geben sie eine gültige E-Mail-Adresse an.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
        this.spinner.hide();
        // is email already used
      } else {
        this.afDatabase.database.ref('/users/banken/').orderByChild('email').equalTo(this.email).once("value", snapshot => {
          let emailExists = snapshot.val();
          if (emailExists) {
            let toast: Toast = {
              type: 'info',
              title: 'Diese E-Mail-Adresse ist bereits in Benutzung.',
              showCloseButton: true
            }
            this.toasterService.pop(toast);
            this.spinner.hide();
          } else {
            // valid. register user
            this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password).then(userObject => {
              let userId = userObject.user.uid;
              this.activeJobs = 0;
              this.inactiveJobs = 0;
              this.jobCount = 0;
              if (!this.website) {
                this.website = "";
              }
              if (!this.applications) {
                this.applications = "";
              }
              if (!this.balance) {
                this.balance = "";
              }
              // register successfull -> write data 
              const newUser = this.afDatabase.object("/users/banken/" + userId);
              let date = Date.now();
              newUser.set({
                userId: userId,
                email: this.email,
                company: this.company,
                partner: this.partner,
                phone: this.phone,
                adress: this.adress,
                plz: this.plz,
                city: this.city,
                country: this.country,
                website: this.website,
                applications: this.applications,
                balance: this.balance,
                date: date,
                statistic: {
                  inactiveJobs: this.inactiveJobs,
                  activeJobs: this.activeJobs,
                  jobCount: this.jobCount
                }
              }).then(() => {
                this.spinner.hide();
                this.router.navigateByUrl('/dashboard');
                location.reload(); 
              }).catch((err) => {
                console.log("err: ", err);
              });
            });
          }
        });
      }
    }
  }
}
