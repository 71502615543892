import { Component, OnInit } from '@angular/core';
import { DataproviderService } from '../provider/dataprovider.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AuthService } from '../../providers/auth.service';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';

@Component({
  selector: 'app-bewerber-verwalten',
  templateUrl: './bewerber-verwalten.component.html',
  styleUrls: ['./bewerber-verwalten.component.less']
})
export class BewerberVerwaltenComponent implements OnInit {

  searchPre: any;
  searchSur: any;
  bewerber: any;

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public dataProvider: DataproviderService,
    public toasterService: ToasterService,
    private spinner: Ng4LoadingSpinnerService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getData();
  }

  /**
   * deleteBewerber(user,i) deletes the selected user
   * @param user the specified user to delete
   * @param i the index to splice in frontend list
   */
  deleteBewerber(user, i){
    this.spinner.show();
    if (window.confirm("Nutzer wirklich löschen?")) {
      // first count Bewerbungen for DashboardPage
      this.dataProvider.deleteBewerber(user);
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    this.bewerber.splice(i, 1);
  }

  /**
   * getData() - gets BewerberData
   */
  getData(){
    this.dataProvider.getBewerber().then((bewerber) => {
      this.bewerber = bewerber;
      console.log(bewerber);
      this.spinner.hide();
    });
  }

}
