// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase : {
      apiKey: "AIzaSyBwF_OPKv-f4yhbIqVytqq8oPPRe_zduBg",
      authDomain: "myfugo-8265f.firebaseapp.com",
      databaseURL: "https://myfugo-8265f.firebaseio.com",
      projectId: "myfugo-8265f",
      storageBucket: "myfugo-8265f.appspot.com",
      messagingSenderId: "955535700513"
  },

  googleMapsKey: 'AIzaSyD6Zjvi74vWRs1QBCLOSzsoPQNhAUOmpJM',
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

/* # This file was originally created by the Android Tools, but is now
# used by cordova-android to manage the state of the various third party
# libraries used in your application

# This is the Library Module that contains the Cordova Library, this is not
# required when using an AAR

# This is the application project.  This is only required for Android Studio Gradle projects

# Project target.
target=android-27
android.library.reference.1=CordovaLib
android.library.reference.2=app
cordova.system.library.1=com.android.support:support-v4:24.1.1+
cordova.system.library.2=com.android.support:support-v4:24.1.1+
cordova.gradle.include.1=cordova-plugin-firebase/myfugo-build.gradle
cordova.system.library.3=com.google.android.gms:play-services-tagmanager:+
cordova.system.library.4=com.google.firebase:firebase-core:+
cordova.system.library.5=com.google.firebase:firebase-messaging:+
cordova.system.library.6=com.google.firebase:firebase-config:+
cordova.system.library.7=com.google.firebase:firebase-perf:+ */