import { Key } from 'protractor';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { database, app } from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase';
import { Router } from '@angular/router';
import { resolve, reject } from 'q';
import { AngularFireStorage } from 'angularfire2/storage';
import { AuthService } from 'src/app/providers/auth.service';

@Injectable({
  providedIn: 'root'
})
export class DataproviderService {

  admin: any;
  jobCount: any;
  user: any;
  bewerber: any;

  constructor(
    public afAuth: AngularFireAuth,
    public afDatabase: AngularFireDatabase,
    public storage: AngularFireStorage,
    private router: Router,
    public authService: AuthService,
  ) {
    this.jobCount = 0;
  }

  /**
   * tests if email is valid
   * @param email the email string to validate
   * @returns the email in lowercase if its valid
   */
  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  /**
   * deletes the given account
   * @param account the account to delete
   */
  deleteAccount(account) {
    console.log(account);
    let deleter = this.afDatabase.database.ref('users').child('banken');
    let userId = account.userId;
    deleter.child(userId).remove();
  }

  /**
   * deletes jobs of a userId
   * @param userId the userId to which the jobs belong
   */
  delAccountJobs(userId) {
    let delJobs = this.afDatabase.database.ref('jobs').child(userId);
    delJobs.remove();
  }

  /**
   * deletes a bewerber account from admin backend
   * @param account the bewerber account to delete
   */
  deleteBewerber(account) {
    console.log(account);
    // this.deleteBewerberStorage(account).then(() => {
    let deleter = this.afDatabase.database.ref('users').child('bewerber');
    let userId = account.userId;
    deleter.child(userId).remove();
    /* }).catch(err => {
      console.log('err in delete bewerber from storage: ', err);
    }); */
  }

  /* deleteBewerberStorage(account) {
    return new Promise((resolve, reject) => {
      console.log('bewerber in userProvider deletebewerber(): ', account);
      this.storage.storage.ref(account.userId).delete().then(() => {
        resolve();
      }).catch(err => {
        console.log('err: ', err);
        reject(err);
      });
    });
  } */

  /**
   * deletes an application from DB
   * @param application the application to delete from DB
   */
  deleteApplication(application) {
    return new Promise((resolve, reject) => {
      let applicationRef = this.afDatabase.database.ref('applications').child(application.userId).child(application.pushId);
      applicationRef.remove().then(() => {
        this.updateCounts(application).then(() => {
          resolve();
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /**
   * deletes storage data belonging to an application
   * @param application the application whichs data needs to be deleted from storage (vids)
   */
  deleteApplicationStorage(application) {
    return new Promise((resolve, reject) => {
      console.log('application in userProvider deleteApplication(): ', application);
      let vidKeys = Object.keys(application.questionsStatus);
      let re = new RegExp('q[1-5]');
      vidKeys.forEach(videoUrl => {
        if (videoUrl.match(re)) {
          console.log('found video match on regex: ', videoUrl);
        } else {
          if (typeof (application.questionsStatus[videoUrl]) === "string" && application.questionsStatus[videoUrl] != '') {
            console.log(videoUrl);
            console.log('there are strings here...: ', application.questionsStatus[videoUrl]);
            let vidUrl = this.storage.storage.refFromURL(application.questionsStatus[videoUrl]);
            if (vidUrl != null) {
              console.log('not null vidUrl, ', vidUrl);
              vidUrl.delete().catch((err) => {
                if (err.code == 'storage/object-not-found') {
                  console.log(err);
                  resolve();
                }
              });
            }
          }
        }
      }, error => {
        reject(error);
      });
      resolve();
    });
  }

  /**
   * deletes a Mail based on the type
   * @param type type of the Mail (WelcomeA, WelcomeB, NewApplication etc... )
   */
  deleteMail(type) {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let delMail = this.afDatabase.database.ref('users').child(userId).child('emailVorlage').child(type);
          delMail.remove().then(() => {
            resolve();
          }).catch((err) => {
            reject(err);
          });
        }
      });
    });
  }

  /**
   * gets Accountinformation for a specific Bank
   * @returns an Array with all Banken 
   */
  getAccountData() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(
        auth => {
          console.log('bankuser status from data: ', this.authService.currentBank);
          if (this.authService.adminUser) {
            let accountRef = this.afDatabase.database.ref("users").child('banken');
            accountRef.once("value", snapshot => {
              let accounts = snapshot.val();
              if (accounts) {
                let keys = Object.keys(accounts);
                let temp = new Array<any>();
                keys.forEach(key => {
                  // key userId exists
                  if (accounts[key].userId != "" && accounts[key].userId) {
                    temp.push(accounts[key]);
                  }
                });
                temp.sort((val1, val2) => {
                  return val2.date - val1.date;
                });
                resolve(temp);
              }
            });
          }
          if (auth != null) {
            let accountRef = this.afDatabase.database.ref("users").child('banken');
            accountRef.once("value", snapshot => {
              let accounts = snapshot.val();
              if (accounts) {
                let keys = Object.keys(accounts);
                let temp = new Array<any>();
                keys.forEach(key => {
                  // key userId exists
                  if (accounts[key].userId != "" && accounts[key].userId) {
                    temp.push(accounts[key]);
                  }
                });
                temp.sort((val1, val2) => {
                  return val2.date - val1.date;
                });
                resolve(temp);
              }
            });
          }
        }
      )
    });
  }

  /**
   * gets the Admin
   * @returns the admin if logged into admin account
   */
  getAdmin() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(
        (auth) => {
          if (auth != null) {
            let userId = this.afAuth.auth.currentUser.uid; //id of current user
            this.afDatabase.database.ref("users/").child(userId).once("value", snapshot => { //snapshot of current users data
              this.admin = snapshot.val();
              resolve(this.admin);
            }, error => {
              console.log(error);
            });
          }
        });
    });
  }

  /**
   * gets all Bewerber accounts 
   * @returns an Array of all Bewerbers
   */
  getBewerber() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe(
        auth => {
          if (auth != null) {
            let accountRef = this.afDatabase.database.ref("users").child('bewerber');
            accountRef.once("value", snapshot => {
              let accounts = snapshot.val();
              if (accounts) {
                let keys = Object.keys(accounts); //userId's 
                let temp = new Array<any>();
                keys.forEach(key => {
                  // key userId exists
                  if (accounts[key].userId != "" && accounts[key].userId) {
                    temp.push(accounts[key]); // userProfile
                  }
                });
                temp.sort((val1, val2) => {
                  return val2.date - val1.date; //nach erstelldatum sortiert
                });
                resolve(temp);
              }
            });
          }
        }
      )
    });
  }

  // singleBewerber for Admins Bewerber-Details Page
  /**
   * gets Accountdata of a specific Bewerber
   * @param userId the specific Id of the Bewerber to get
   * @returns the bewerber if found if found, else error
   */
  getBewerberData(userId) {
    return new Promise<any>((resolve, reject) => {
      this.afDatabase.database.ref("users").child("bewerber").child(userId).once("value", snapshot => {
        this.bewerber = snapshot.val();
        resolve(this.bewerber);
      }, error => {
        console.log(error);
        reject('bewerber nicht gefunden: ' + error);
      });
    });
  }

  /**
   * gets all Bewerber for the admin account
   * @returns an Array with all Bewerber
   */
  getBewerbungenAdmin() {
    return new Promise((resolve, reject) => {
      let applicationRef = this.afDatabase.database.ref('applications');
      applicationRef.once('value', snap => {
        let allApplicationData = snap.val();
        if (allApplicationData) {
          let userKeys = Object.keys(allApplicationData);
          let applicationsArray = new Array<any>()
          userKeys.forEach(applicationId => {
            let applicationKeys = Object.keys(allApplicationData[applicationId]);
            applicationKeys.forEach(application => {
              if (allApplicationData[applicationId][application].sendDate) {
                allApplicationData[applicationId][application].sendDate = new Date(allApplicationData[applicationId][application].sendDate).toDateString();
                applicationsArray.push(allApplicationData[applicationId][application]);
              }
            });
          });
          applicationsArray.sort((val1, val2) => {
            return val2.sendDate - val1.sendDate;
          });
          resolve(applicationsArray);
        } else {
          reject();
        }
      });
    });
  }

  /**
   * gets a specific mail
   * @param type the type of the Mail
   * @returns the mail with type type
   */
  getMailDetail(type) {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid; //id of current user
          this.afDatabase.database.ref('users').child(userId).child('emailVorlage').child(type.type).once("value", snap => {
            let mail = snap.val();
            if (mail) {
              console.log('mail in datagetMailDetail(): ', mail);
              resolve(mail);
            } else {
              resolve(null);
            }
          }, error => {
            console.log('getMails error: ', error);
            reject(error);
          });
        }
      });
    });
  }

  /**
   * gets all Mails
   * @returns an Array with all Mails null if none are there, or error
   */
  getMails() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid; //id of current user
          this.afDatabase.database.ref("users/").child(userId).child('emailVorlage').once("value", snap => {
            let mails = snap.val();
            if (mails) {
              let keys = Object.keys(mails);
              let temp = new Array<any>();
              keys.forEach(key => {
                temp.push(mails[key]);
              });
              resolve(temp);
            } else {
              resolve(null);
            }
          }, error => {
            console.log('getMails error: ', error);
            reject(error);
          });
        }
      });
    });
  }

  /**
   * gets all pushmsgs
   * @returns all pushMsgs sent
   */
  getPush() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let pushRef = this.afDatabase.database.ref('users').child(userId).child('pushMsg');
          pushRef.once('value', snap => {
            let messages = snap.val();
            if (messages) {
              let pushKeys = Object.keys(messages);
              let temp = new Array<any>();
              pushKeys.forEach(push => {
                temp.push(messages[push]);
              });
              temp.sort((val1, val2) => {
                return val2.date - val1.date;
              });
              console.log('temp: ', temp);
              resolve(temp);
            } else {
              resolve(null);
            }
          }).catch((err) => {
            console.log('err in getPush: ', err);
            reject(err);
          });
        }
      });
    });
  }

  /**
   * gets bank account Data
   * @param userId the userId of the bank account
   */
  getUserData(userId) {
    return new Promise<any>((resolve, reject) => {
      this.afDatabase.database.ref("users").child("banken").child(userId).once("value", snapshot => {
        this.user = snapshot.val();
        resolve(this.user);
      }, error => {
        console.log(error);
        reject();
      });
    });
  }

  /**
   * saves a Mailobject in Db
   * @param mailObject the Mail which is saves
   * @param type the type of the Mail
   */
  saveMail(mailObject, type) {
    // mail.message, mail.title
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let mailRef = this.afDatabase.database.ref('users').child(userId).child('emailVorlage').child(type);
          let date = Date.now();
          mailObject.date = date;
          mailRef.set(mailObject).then(() => {
            resolve();
          }).catch((err) => {
            console.log('err in saveMail dataProv: ', err);
            reject(err);
          });
        }
      });
    });
  }

  /**
   * saves a Pushmsg in DB
   * @param dataObject the pushMsg object
   */
  savePush(dataObject) {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let pushRef = this.afDatabase.database.ref('users').child(userId).child('pushMsg');
          let task = pushRef.push();
          let pushId = task.key;
          dataObject.pushId = pushId;
          task.set(dataObject).then(() => {
            console.log('pushmsg saved');
            resolve();
          }).catch((err) => {
            console.log('err in savePush: ', err);
            reject(err);
          });
        }
      });
    });
  }

  /**
   * updates the count of the selected application
   * @param application the application which counts are to update
   */
  updateCounts(application) {
    // Bewerbung löschen ->  job.bewerbungen -1 | bewerber.applicationCount -1 | bank.statistics.applications
    console.log('updateCount(), application: ', application);
    return new Promise((resolve, reject) => {
      let jobCountRef = this.afDatabase.database.ref('jobs').child(application.companyId).child(application.jobId).child('bewerbungen');
      jobCountRef.once('value', snap => {
        let count = snap.val();
        if (count) {
          console.log(count);
          count -= 1;
          jobCountRef.parent.update({ bewerbungen: count });
        }
      }).then(() => {
        let appCountRef = this.afDatabase.database.ref('users').child('bewerber').child(application.userId).child('applicationCount');
        appCountRef.once('value', snap => {
          let count = snap.val();
          if (count) {
            console.log('applicationcount: ', count);
            count -= 1;
            appCountRef.parent.update({ applicationCount: count });
          }
        }).then(() => {
          let companyRef = this.afDatabase.database.ref('users').child('banken').child(application.companyId).child('statistic');
          companyRef.once("value", snap => {
            let company = snap.val();
            if (company) {
              console.log('statisik = ', company);
              let count = company.applications;
              if (count) {
                count -= 1;
                companyRef.update({ applications: count }).then(() => {
                  console.log('everything updated')
                  resolve();
                }).catch((err) => {
                  console.log('err in bankappcount update_ ', err);
                  reject(err);
                });
              }
            }
          }).catch((err) => {
            console.log('err in bankappcount update_ ', err);
            reject(err);
          });
        }).catch((err) => {
          console.log('err in bankappcount update_ ', err);
          reject(err);
        });
      }).catch((err) => {
        console.log('err in bankappcount update_ ', err);
        reject(err);
      });
    });
  }

  /**
   * Updates a Bewerber account
   * @param updateObject the update Information
   * @param userId the Id of the Bewerber
   */
  updateBewerber(updateObject, userId) {
    return new Promise((resolve, reject) => {
      if (this.validateEmail(updateObject.email) || !updateObject.email) {
        let userRef = this.afDatabase.database.ref('users').child('bewerber').child(userId);
        userRef.once("value", snapshot => {
          userRef.update(updateObject);
          console.log("user in db updated");
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      }
      else {
        reject("Keine gültige E-Mail-Adresse eingegeben!");
      }
    });
  }

  /**
   * updates a Banken Account
   * @param updateObject the UpdateInformation
   * @param userId the userId of the Banken account
   */
  updateData(updateObject, userId) {
    return new Promise((resolve, reject) => {
      if (this.validateEmail(updateObject.email) || !updateObject.email) {
        let userRef = this.afDatabase.database.ref('users').child('banken').child(userId);
        userRef.once("value", snapshot => {
          userRef.update(updateObject);
          if (updateObject.company && updateObject.company != '') {
            this.updateDataRecursive(updateObject.company, userId);
          }
          console.log("user in db updated");
          resolve();
        }).catch((error) => {
          console.log(error);
          reject(error);
        });
      }
      else {
        reject("Keine gültige E-Mail-Adresse eingegeben!");
      }
    });
  }

  updateDataRecursive(companyName, userId) {
    return new Promise((resolve, reject) => {
      let jobRef = this.afDatabase.database.ref('jobs').child(userId);
      jobRef.once("value", snap => {
        let jobs = snap.val();
        if (jobs) {
          let jobIds = Object.keys(jobs);
          jobIds.forEach(id => {
            let updateObject = {
              company: companyName
            };
            let updateRef = jobRef.child(id);
            updateRef.update(updateObject).then(() => {
              console.log("updated Jobs recursivly");
              resolve();
            }).catch((err) => {
              console.log('error in recursive job update: ', err);
              reject(err);
            });
          });
        } else {
          console.log("company has no jobs yet. do nothing.");
          resolve(null);
        }
      });
    });
  }

  /**
   * updates a Mail in DB
   * @param mailObject the updateDate for the Mail
   */
  updateMail(mailObject) {
    console.log('updateMail MailObject: ', mailObject);
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let mailRef = this.afDatabase.database.ref('users').child(userId).child('emailVorlage').child(mailObject.type);
          let date = Date.now();
          mailObject.date = date;
          mailRef.update(mailObject).then(() => {
            resolve();
          }).catch((err) => {
            console.log('err in saveMail dataProv: ', err);
            reject(err);
          });
        }
      });
    });
  }

}
