import { Component, OnInit } from '@angular/core';
import { UserProviderService } from '../../providers/user-provider.service';
import { JobProviderService } from '../../providers/job-provider.service';
import { DataproviderService } from '../provider/dataprovider.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { AuthService } from '../../providers/auth.service';
import { SearchPipe } from '../../pipe/search.pipe';

@Component({
  selector: 'app-banken-verwalten',
  templateUrl: './banken-verwalten.component.html',
  styleUrls: ['./banken-verwalten.component.less'],
})

export class BankenVerwaltenComponent implements OnInit {

  searchtext: any;
  searchPartner: any;
  accounts: any;
  user: any;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    private userProvider: UserProviderService,
    private dataProvider: DataproviderService,
    private spinner: Ng4LoadingSpinnerService,
    public auth: AuthService,
    private jobService: JobProviderService,
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.getData();
  }

  /**
   * deleteAccount(account,i) delete's the specified account
   * @param account the userAccount to delete
   * @param i the index to splice the account out of the frontend
   */
  deleteAccount(account, i) {
    this.spinner.show();
    if (window.confirm("Nutzer wirklich löschen?")) {
      // first call jobService to update JobCounts for admin page, then delet accountdata
      this.jobService.overallJobCount(account.plz, account);
      this.dataProvider.deleteAccount(account);
    }
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);

    this.accounts.splice(i, 1);
    this.delAccountJobs(account.userId);
  }

  /**
   * delAccountJobs(userId) deletes all Jobs assigned to a UserAccount
   * @param userId the userId of the Account that got deleted
   */
  delAccountJobs(userId) {
    this.dataProvider.delAccountJobs(userId);
  }


  /**
   * getData() gets the userData and triggers getAccountData()
   */
  getData() {
    this.userProvider.getUserData().then((user) => {
      this.user = user;
      this.getAccountData();
    });
  }

  /**
   * getAccountData() gets all Banken Accounts
   */
  getAccountData() {
    this.dataProvider.getAccountData().then((accounts) => {
      this.accounts = accounts;
      this.spinner.hide();
    });
  }

}
