import { Component, OnInit } from '@angular/core';

import { UserProviderService } from '../../providers/user-provider.service';
import { JobProviderService } from '../../providers/job-provider.service';
import { DataproviderService } from '../provider/dataprovider.service';
import { ToasterConfig, ToasterModule, ToasterService, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { JobcodePipe } from '../../pipe/jobcode.pipe';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-jobcodes-verwalten',
  templateUrl: './jobcodes-verwalten.component.html',
  styleUrls: ['./jobcodes-verwalten.component.less']
})
export class JobcodesVerwaltenComponent implements OnInit {

  jobs:any;
  searchStatus: any;
  searchText: any;
  searchJob: any;
  searchBank: any;


  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });
  
  constructor(
    private userProvider: UserProviderService,
    private dataProvider: DataproviderService,
    public auth: AuthService,
    private jobService: JobProviderService,
    private toasterService: ToasterService,
    private spinnerService: Ng4LoadingSpinnerService
  ) { }

  ngOnInit() {
    this.spinnerService.show();
    this.jobService.getAllJobs().then((jobs) => {
      this.jobs = jobs;
      this.spinnerService.hide();
    });
  }

  /**
   * deletes the job from the list if jobs
   * @param job the job to delete
   * @param index the index of the job in the frontend list
   */
  deleteJob(job: any, index: any){
    if(window.confirm('Jobcode wirklich löschen?')){
      this.spinnerService.show();
      this.jobService.deleteJob(job)
      .then(()=>{
        setTimeout(()=>{
          this.spinnerService.hide();
          let toast: Toast = {
            type: 'success',
            title: 'Jobcode erfolgreich gelöscht!',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.jobs.splice(index,1);
        },2000);
      })
      .catch((err)=>{
        console.log(err);
      });
    } 
  }

}
