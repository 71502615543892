import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { DataproviderService } from '../provider/dataprovider.service';
import { AuthService } from 'src/app/providers/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AngularFireDatabase } from 'angularfire2/database';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Toast, ToasterConfig, ToasterService } from '../../../../node_modules/angular2-toaster';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from 'angularfire2/storage';

@Component({
  selector: 'app-bank-detail',
  templateUrl: './bank-detail.component.html',
  styleUrls: ['./bank-detail.component.less']
})
export class BankDetailComponent implements OnInit {

  userId: any;
  user: any;
  form;
  partnerStatus;
  downloadURL: string;
  link: Observable<string>;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 2,
    animation: 'slideUp'
  });

  constructor(
    public userProvider: UserProviderService,
    public auth: AuthService,
    private spinnerService: Ng4LoadingSpinnerService,
    public activeRoute: ActivatedRoute,
    private afDatabase: AngularFireDatabase,
    private fb: FormBuilder,
    private dataProvider: DataproviderService,
    public toasterService: ToasterService,
    public storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.partnerStatus = 'false';
    this.createForm().then(() => {
      this.activeRoute.params.subscribe(param => {
        this.userId = param.pushId;
        if (this.userId != "" && this.userId) {
          this.spinnerService.show();
          // get user with id -> then bla bla
          this.dataProvider.getUserData(this.userId).then((user: any) => {
            this.user = user;
            this.partnerStatus = user.partnerStatus;
            this.updateForm();
            this.form.patchValue({ partnerStatus: this.partnerStatus })
            this.spinnerService.hide();
          }).catch((error) => {
            console.log(error);
          });
        }
      });
    });
  }

  /**
  * This is the updateForm function
  * Updates Form in View
  */
  updateForm() {
    this.form = this.fb.group({
      company: [this.user.company, Validators.required],
      partner: [this.user.partner, Validators.required],
      adress: [this.user.adress, Validators.required],
      phone: [this.user.phone, Validators.required],
      plz: [this.user.plz, Validators.required],
      email: [this.user.email, Validators.required],
      city: [this.user.city, Validators.required],
      website: [this.user.website],
      country: [this.user.country, Validators.required],
      applications: [this.user.applications],
      balance: [this.user.balance],
      partnerStatus: [this.user.partnerStatus, Validators.required]
    });
  }

  /* 
  * CreateForm: Creates Form in the View
  */
  createForm() {
    return new Promise<any>((resolve, reject) => {
      this.form = new FormGroup({
        company: new FormControl(),
        partner: new FormControl(),
        adress: new FormControl(),
        phone: new FormControl(),
        plz: new FormControl(),
        email: new FormControl(),
        city: new FormControl(),
        website: new FormControl(),
        country: new FormControl(),
        applications: new FormControl(),
        balance: new FormControl(),
      });
      this.form = this.fb.group({
        company: ['', Validators.required],
        partner: ['', Validators.required],
        adress: ['', Validators.required],
        phone: ['', Validators.required],
        plz: ['', Validators.required],
        email: ['', Validators.required],
        city: ['', Validators.required],
        website: [''],
        country: ['', Validators.required],
        applications: [''],
        balance: [''],
        partnerStatus: ['']
      });
      resolve();
    });
  }

  /**
   * updateData()
   * Takes FormData and uses it to call the dataProviderservice 
   * which uploads to db
   */
  updateData() {
    this.spinnerService.show();
    let { company, partner, plz, city, country,
      email, website, balance, applications, adress, phone, partnerStatus }
      = this.form.value;
    let updateObject: any = {};

    if (company && (this.user.company != company)) {
      updateObject.company = company;
    }
    if (partner && (this.user.partner != partner)) {
      updateObject.partner = partner;
    }
    if (plz && (this.user.plz != plz)) {
      updateObject.plz = plz;
    }
    if (city && (this.user.city != city)) {
      updateObject.city = city;
    }
    if (country && (this.user.country != country)) {
      updateObject.country = country;
    }
    if (email && (this.user.email != email)) {
      updateObject.email = email;
    }
    if ((this.user.website != website)) {
      updateObject.website = website;
    }
    if ((this.user.balance != balance)) {
      updateObject.balance = balance
    }
    if (phone && (this.user.phone != phone)) {
      updateObject.phone = phone
    }
    if ((this.user.applications != applications)) {
      updateObject.applications = applications;
    }
    if (adress && (this.user.adress != adress)) {
      updateObject.adress = adress;
    }
    if (this.user.partnerStatus != partnerStatus) {
      updateObject.partnerStatus = partnerStatus;
    }
    if (!this.form.valid) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
    else if (updateObject && Object.keys(updateObject).length != 0) {
      this.dataProvider.updateData(updateObject, this.userId).then(() => {
        let toast: Toast = {
          type: 'success',
          title: 'Profilinformationen erfolgreich geändert!',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
        this.spinnerService.hide();
        this.recreateFormData();
      }).catch((error) => {
        let toast: Toast = {
          type: 'error',
          title: error
        }
        this.toasterService.pop(toast);
      })
    } else if (Object.keys(updateObject).length == 0) {
      let toast: Toast = {
        type: 'info',
        title: 'Sie haben keine Änderungen vorgenommen.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
  }

  /**
   * recreateFormData
   * recreates Form with Data from dataProvider
   */
  recreateFormData() {
    this.dataProvider.getUserData(this.userId).then((user: any) => {
      this.user = user;
      this.updateForm();
      this.spinnerService.hide();
    })
  }

  /**
   * uploadImage
   * uploads a choosen image File 
   * @param event the File a user selects from localStorage
   * @returns true if the files downloadLink is return from the storage ref
   */
  uploadImage(event) {
    this.spinnerService.show();
    return new Promise(resolve => {
      let file = event.target.files[0];
      let filePath = 'images/' + this.user.userId + '/logo';
      let fileRef = this.storage.ref(filePath);
      let task = this.storage.upload(filePath, file);
      task.snapshotChanges().pipe(
        finalize(() => {
          (this.link = fileRef.getDownloadURL()),
            resolve();
        })
      ).subscribe();
    });
  }

  /**
   * uploadFile 
   * uploads a choosen file to db after uploadimage puts the file to storage
   * @param event the file a user selects from localStorage
   */
  uploadFile(event) {
    this.uploadImage(event).then(() => {
      this.link.subscribe(link => {
        this.downloadURL = link;
        let userRef = this.afDatabase.database.ref("/users/banken/" + this.user.userId);
        let linkRef = userRef.child('logoUrl');
        linkRef.once("value", snapshot => {
          linkRef.set(this.downloadURL);
          let toast: Toast = {
            type: 'success',
            title: 'Logo erfolgreich hochgeladen',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.recreateFormData();
          this.spinnerService.hide();
        });
      });
    });
  }

  /**
   * resetPasswort
   * resets the users Password
   */
  resetPassword() {
    this.spinnerService.show();
    if (!this.user.email) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte geben sie eine E-Mail-Adresse ein.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    } else {
      this.auth.resetPassword(this.user.email).then(() => {
        this.spinnerService.hide();
      })
    }
  }

}
