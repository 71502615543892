import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularFireDatabase } from 'angularfire2/database';
import { database } from 'firebase';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase';

import { Observable, of } from 'rxjs';
import { first } from 'rxjs/operators';

import { ToasterService, ToasterConfig, ToasterModule, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { timingSafeEqual } from 'crypto';

@Injectable()
export class AuthService {
  private user: Observable<firebase.User>;
  private userDetails: firebase.User = null;
  private toasterService;
  public currentBank: any;
  bankId: any;
  public adminUser: boolean;
  currentUserId: any;

  constructor(
    public afAuth: AngularFireAuth,
    public afDb: AngularFireDatabase,
    toasterService: ToasterService,
    private router: Router,
    private spinner: Ng4LoadingSpinnerService
  ) {
    this.user = this.afAuth.authState;
    this.toasterService = toasterService;
    this.user.subscribe(user => {
      if (user) {
        this.userDetails = user;
      } else {
        this.userDetails = null;
      }
    })
  }


  checkUserState() {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid
          this.currentUserId = userId;
          console.log('userId in checkuser: ', userId)
          /* if(userId == this.currentUserId){
            console.log('main acc');
            this.adminUser = true;
            this.afDb.database.ref('/users/banken/' + userId).once('value', snap => {
              this.currentBank = snap.val();
            });
            resolve(true);
          } */
          let adminsRef = this.afDb.database.ref('/users/adminList/').orderByChild('userId').equalTo(userId);
          adminsRef.once("value", snp => {
            let admin = snp.val();
            if(this.currentUserId == userId){}
            console.log('admin gefunden: ', admin);
            if (admin) {
              //bankId finden: 
              this.adminUser = true;
              let keys = Object.keys(admin);
              keys.forEach(entry => {
                this.bankId = admin[entry]['bankId'];
              });
              //zuordnen: 
              this.afDb.database.ref('/users/banken/' + this.bankId).once('value', snap => {
                this.currentBank = snap.val();
              });
              resolve(true);
            } else {
              resolve(false);
            }
          });
        }
      })

    });
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  login(email: string, password: string) {
    this.spinner.show();
    // fields filled?
    if (!email || !password) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinner.hide();
      // fields are valid
    } else {
      // validate email
      if (!this.validateEmail(email)) {
        let toast: Toast = {
          type: 'info',
          title: 'Bitte geben sie eine gültige E-Mail-Adresse an.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
        this.spinner.hide();
      } else {
        // check if bank or bewerber
        return new Promise<any>((resolve, reject) => {
          if (email == 'info@myfugo.de') {
            this.adminUser = false;
            this.afAuth.auth.signInWithEmailAndPassword(email, password).then(user => {
              this.spinner.hide();
              localStorage.setItem("admin", this.afAuth.auth.currentUser.uid);
              resolve(user);
            }).catch(error => {
              if (error.code == 'auth/wrong-password') {
                let toast: Toast = {
                  type: 'info',
                  title: 'Passwort oder E-Mail-Adresse nicht korrekt eingeben',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
                this.spinner.hide();
              }
              if (error.code == 'auth/user-not-found') {
                let toast: Toast = {
                  type: 'info',
                  title: 'Dise E-Mail-Adresse konnte nicht gefunden werden.',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
              }
              console.log(error)
              this.spinner.hide();
            });
          }
          // multi user change 
          let userRef = this.afDb.database.ref('users').child('banken').orderByChild('email').equalTo(email);
          userRef.once('value', snap => {
            let bankAccount = snap.val();
            if (!bankAccount) {
              // check if usermails exist: 
              console.log('kein main nutzer -> check for userEmails');
              let adminsRef = this.afDb.database.ref('/users/adminList/').orderByChild('email').equalTo(email);
              adminsRef.once("value", snp => {
                let admin = snp.val();
                console.log('admin gefunden: ', admin);
                if (admin) {
                  //bankId finden: 
                  let keys = Object.keys(admin);
                  keys.forEach(entry => {
                    this.bankId = admin[entry]['bankId'];
                  });
                  //zuordnen: 
                  this.afDb.database.ref('users/banken/' + this.bankId).once('value', snap => {
                    this.currentBank = snap.val();
                    this.afAuth.auth.signInWithEmailAndPassword(email, password).then(user => {
                      this.spinner.hide();
                      localStorage.setItem("user", this.currentBank.userId);
                      this.adminUser = true;
                      resolve(bankAccount);
                    }).catch((error) => {
                      if (error.code == 'auth/wrong-password') {
                        let toast: Toast = {
                          type: 'info',
                          title: 'Passwort oder E-Mail-Adresse nicht korrekt eingeben',
                          showCloseButton: true
                        }
                        this.toasterService.pop(toast);
                        this.spinner.hide();
                      }
                      if (error.code == 'auth/user-not-found') {
                        let toast: Toast = {
                          type: 'info',
                          title: 'Dise E-Mail-Adresse konnte nicht gefunden werden.',
                          showCloseButton: true
                        }
                        this.toasterService.pop(toast);
                      }
                      console.log(error)
                      this.spinner.hide();
                    });
                  });
                } else {
                  let toast: Toast = {
                    type: 'info',
                    title: 'Dise E-Mail-Adresse konnte nicht gefunden werden.',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                  this.spinner.hide();
                }
              });
            } else if (bankAccount) {
              console.log('bankAccount: ', bankAccount);
              this.adminUser = false;
              this.afAuth.auth.signInWithEmailAndPassword(email, password).then(user => {
                this.spinner.hide();
                localStorage.setItem("user", this.afAuth.auth.currentUser.uid);
                resolve(user);
              }).catch((error) => {
                if (error.code == 'auth/wrong-password') {
                  let toast: Toast = {
                    type: 'info',
                    title: 'Passwort oder E-Mail-Adresse nicht korrekt eingeben',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                  this.spinner.hide();
                }
                if (error.code == 'auth/user-not-found') {
                  let toast: Toast = {
                    type: 'info',
                    title: 'Dise E-Mail-Adresse konnte nicht gefunden werden.',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                }
                console.log(error)
                this.spinner.hide();
              });
            } else {
              let toast: Toast = {
                type: 'info',
                title: 'Dise E-Mail-Adresse konnte nicht gefunden werden.',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
              this.spinner.hide();
            }
          });
        });
      }
    }
  }


  signOut() {
    this.afAuth.auth.signOut();
    localStorage.removeItem("user");
    this.router.navigate(['/']);
  }

  resetPassword(email: string) {
    return new Promise<any>((resolve, reject) => {
      this.afAuth.auth.sendPasswordResetEmail(email).then(() => {
        let toast: Toast = {
          type: 'succes',
          title: 'Wir haben Ihnen eine E-Mail mit der Anleitung zum Zurücksetzen ihres Passworts gesendet.',
          showCloseButton: true
        }
        console.log(email);
        this.toasterService.pop(toast);
        console.log("email sent")
        resolve();
      }).catch((error) => {
        if (error.code == 'auth/invalid-email') {
          let toast: Toast = {
            type: 'info',
            title: 'Bitte geben sie eine gültige E-Mail-Adresse an',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
        }
        console.log(email);
        console.log(error);
        reject(error);
      });
    });
  }

  registerBankuser(userEmail: string, userPass: string) {
    this.checkUserState().then((res) => {
      if (res) {
        console.log('res of checkUser: ', res);
        let superEmail = this.currentBank.email;
        let superId = this.currentBank.userId;
        if (userEmail == superEmail) {
          console.log('email in use check for supermail')
          let toast: Toast = {
            type: 'info',
            title: 'Diese E-Mail-Adresse ist bereits in Benutzung.',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.spinner.hide();
          return;
        }
        let bankRef = this.afDb.database.ref('/users/banken/' + superId + '/userEmails/');
        bankRef.once("value", snp => {
          let bankMails = snp.val();
          if (!bankMails) {
            //superEmail kopieren und liste anlegen: 
            let superData = {
              email: superEmail,
              userId: superId
            }
            bankRef.child(superId).update(superData).then((res) => {
              console.log('admin data angelegt');
            }).catch(err => {
              console.log('err_: ', err);
              let toast: Toast = {
                type: 'info',
                title: 'Etwas ist schiefgelaufen, bitte versuchen Sie es erneut.',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
              this.spinner.hide();
              return;
            });
          }
        }).then(() => {
          this.afDb.database.ref('/users/banken/' + superId + '/userEmails/').orderByChild('email').equalTo(userEmail).once("value", snapshot => {
            // valid. register user
            let snap = snapshot.val();
            console.log(userEmail, 'not in snap of userEmails(should be null): ', snap);
            this.afAuth.auth.createUserWithEmailAndPassword(userEmail, userPass).then(userObject => {
              let userId = userObject.user.uid;
              let date = Date.now();
              // register successfull -> write data 
              let newUserEntry = this.afDb.database.ref('/users/banken/' + superId + '/userEmails/');
              let userPushKey = newUserEntry.push().key;
              let userData = {
                email: userEmail,
                regDate: date,
                userId: userId,
                pushId: userPushKey
              }
              newUserEntry.push(userData).then((res) => {
                console.log('updated: ', res);
                let adminList = this.afDb.database.ref('/users/adminList/');
                let pushKey = adminList.push().key;
                let adminEntry = {
                  email: userEmail,
                  userId: userId,
                  bankId: superId,
                  pushId: pushKey
                }
                adminList.push(adminEntry);
                let toast: Toast = {
                  type: 'info',
                  title: 'Benutzer angelegt, sie werden jetzt ausgeloggt.',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
                this.spinner.hide();
                setTimeout(() => {
                  this.signOut();
                }, 1500);
                return;
              });
            });
          });
        });
      } else {
        this.afAuth.authState.subscribe((auth) => {
          console.log('res of checkUser: ', res);
          let superEmail = this.afAuth.auth.currentUser.email;
          let superId = this.afAuth.auth.currentUser.uid;
          if (userEmail == superEmail) {
            let toast: Toast = {
              type: 'info',
              title: 'Diese E-Mail-Adresse ist bereits in Benutzung.',
              showCloseButton: true
            }
            this.toasterService.pop(toast);
            this.spinner.hide();
            return;
          }
          let bankRef = this.afDb.database.ref('/users/banken/' + superId + '/userEmails/');
          bankRef.once("value", snp => {
            let bankMails = snp.val();
            if (!bankMails) {
              //superEmail kopieren und liste anlegen: 
              let superData = {
                email: superEmail,
                userId: superId
              }
              bankRef.child(superId).update(superData).then((res) => {
                console.log('admin data angelegt');
              }).catch(err => {
                console.log('err_: ', err);
                let toast: Toast = {
                  type: 'info',
                  title: 'Etwas ist schiefgelaufen, bitte versuchen Sie es erneut.',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
                this.spinner.hide();
                return;
              });
            }
          }).then(() => {
            this.afDb.database.ref('/users/banken/' + superId + '/userEmails/').orderByChild('email').equalTo(userEmail).once("value", snapshot => {
              // valid. register user
              let snap = snapshot.val();
              console.log(userEmail, 'not in snap of userEmails(should be null): ', snap);
              this.afAuth.auth.createUserWithEmailAndPassword(userEmail, userPass).then(userObject => {
                let userId = userObject.user.uid;
                let date = Date.now();
                // register successfull -> write data 
                let newUserEntry = this.afDb.database.ref('/users/banken/' + superId + '/userEmails/');
                let userPushKey = newUserEntry.push().key;
                let userData = {
                  email: userEmail,
                  regDate: date,
                  userId: userId,
                  pushId: userPushKey
                }
                newUserEntry.push(userData).then((res) => {
                  console.log('updated: ', res);
                  let adminList = this.afDb.database.ref('/users/adminList/');
                  let pushKey = adminList.push().key;
                  let adminEntry = {
                    email: userEmail,
                    userId: userId,
                    bankId: superId,
                    pushId: pushKey
                  }
                  adminList.push(adminEntry);
                  let toast: Toast = {
                    type: 'info',
                    title: 'Benutzer angelegt, sie werden jetzt ausgeloggt.',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                  this.spinner.hide();
                  setTimeout(() => {
                    this.signOut();
                  }, 1500);
                  return;
                });
              });
            });
          });
        });
      }
    });
  }

}
