import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { JobProviderService } from 'src/app/providers/job-provider.service';
import { AuthService } from '../../providers/auth.service';
import { ToasterConfig, ToasterModule, ToasterService, Toast } from 'angular2-toaster';
import { Key } from 'protractor';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';


@Component({
  selector: 'app-jobcode',
  templateUrl: './jobcode.component.html',
  styleUrls: ['./jobcode.component.less']
})
export class JobcodeComponent implements OnInit {

  jobs: any;
  private toasterService;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public userProvider: UserProviderService,
    private jobProvider: JobProviderService,
    public auth: AuthService,
    private router: Router,
    toasterService: ToasterService,
    private spinnerService: Ng4LoadingSpinnerService
  ) {

    this.getData();
    this.toasterService = toasterService;
  }

  ngOnInit() {
    console.log("jobcodes loaded")
  }

  getData() {
    this.auth.checkUserState().then(res => {
      if (res) {
        console.log('2nd level user found');
      }

      this.getJobs();
    });
    this.spinnerService.show();
  }

  getJobs() {
    this.jobProvider.getJobs().then((jobs: any) => {
      if (jobs) {
        this.jobs = jobs;
      }
      this.spinnerService.hide();
    })
  }

  deleteJob(job: any, index: any) {
    if (window.confirm('Jobcode wirklich löschen?')) {
      this.jobProvider.deleteJob(job)
        .then(() => {
          this.jobs.splice(index, 1);
          let toast: Toast = {
            type: 'success',
            title: 'Jobcode erfolgreich gelöscht!',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

}
