import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(companies: any, searchText: any): any {
    if (searchText == null) {
      return companies;
    } else {
      return companies.filter((companyName) => {
        return companyName.company.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }

}

@Pipe({
  name: 'searchPartner'
})
export class SearchPartnerPipe implements PipeTransform {

  transform(partners: any, searchText: any): any {
    if (searchText == null) {
      return partners;
    } else {
      return partners.filter((partnerName) => {
        return partnerName.partner.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }

}

@Pipe({
  name: 'searchPre'
})
export class SearchPrePipe implements PipeTransform {

  transform(users: any, searchText: any): any {
    if (searchText == null) {
      return users;
    } else {
      return users.filter((user) => {
        return user.vorname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }

}

@Pipe({
  name: 'searchSur'
})
export class SearchSurPipe implements PipeTransform {

  transform(users: any, searchText: any): any {
    if (searchText == null) {
      return users;
    } else {
      return users.filter((user) => {
        return user.nachname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }

}

@Pipe({
  name: 'searchApplicant'
})
export class SearchApplicantPipe implements PipeTransform {

  transform(applications: any, searchText: any): any {
    if (searchText == null) {
      return applications;
    } else {
      return applications.filter((data) => {
        return data.userData.vorname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'searchApplicantSur'
})
export class SearchApplicantPrePipe implements PipeTransform {

  transform(applications: any, searchText: any): any {
    if (searchText == null) {
      return applications;
    } else {
      return applications.filter((data) => {
        return data.userData.nachname.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'searchApplicantJc'
})
export class SearchApplicantJCPipe implements PipeTransform {

  transform(applications: any, searchText: any): any {
    if (searchText == null) {
      return applications;
    } else {
      return applications.filter((data) => {
        console.log('data: ', data);
        return data.jobCode.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'searchApplicantJt'
})
export class SearchApplicantJtPipe implements PipeTransform {

  transform(applications: any, searchText: any): any {
    if (searchText == null) {
      return applications;
    } else {
      return applications.filter((data) => {
        return data.jobTitle.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}