import { Component, OnInit } from '@angular/core';
import { UserProviderService } from '../../providers/user-provider.service';
import { DataproviderService } from '../../admin/provider/dataprovider.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.less']

})
export class SidebarComponent implements OnInit {
  public expand: boolean;
  public topExpand: boolean;
  admin: any;

  constructor( private userService: UserProviderService, private dataService: DataproviderService) {
    this.expand = false;
    this.topExpand = true;
  }

  ngOnInit() {
    this.getData();
  }

  getData(){
    this.dataService.getAdmin().then((admin) =>{
      this.admin = admin;
    });
    this.userService.getUserData();
  }


  public expandNav(){
    this.expand = !this.expand;
    this.topExpand = !this.topExpand;
  }

}
