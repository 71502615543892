import { DataproviderService } from './../provider/dataprovider.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';


import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-push',
  templateUrl: './push.component.html',
  styleUrls: ['./push.component.less']
})
export class PushComponent implements OnInit {

  form: FormGroup;
  pushMsg = new Array<any>();

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private toasterService: ToasterService,
    public dataProvider: DataproviderService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.createForm().then(() => {
      this.dataProvider.getPush().then((messages: Array<any>) => {
        if (messages) {
          this.pushMsg = messages;
        } else {
          console.log('no messages to get');
        }
      }).catch((err) => {
        console.log(err);
      })
    });
  }

  createForm() {
    return new Promise<any>((resolve, reject) => {
      this.form = new FormGroup({
        title: new FormControl(),
        message: new FormControl(),
      });
      this.form = this.fb.group({
        title: ['', Validators.required],
        message: ['', Validators.required]
      });
      resolve();
    });
  }

  deletePush() {
    alert('hier kann später die Pushmsg gelöscht werden.');
  }

  sendPush() {
    let { title, message } = this.form.value;
    if (!title || !message) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Pflichtfelder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    } else {
      let formRequest = {
        title: title,
        message: message,
        date: Date.now(),
      };
      this.dataProvider.savePush(formRequest).then(() => {
        let toast: Toast = {
          type: 'succes',
          title: 'Push gesendet!',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      });
      this.pushMsg.push(formRequest);
      this.form.reset();
    }
  }

}
