import { Component } from '@angular/core';

import { Router } from '@angular/router';

import { AngularFireAuth } from 'angularfire2/auth';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AuthGuard } from '../core/auth.guard'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  title = 'myfugo';
  rootPage: any;
  constructor(public afAuth: AngularFireAuth, private router: Router, public spinnerService: Ng4LoadingSpinnerService, private ag: AuthGuard) { 
    if(localStorage.getItem("user")){
      this.router.navigate(['dashboard']);
    }else{
      console.log("nicht eingeloggt");
    }
   }
}
