import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';

import { ToasterModule, ToasterService, ToasterConfig, Toast, ToasterContainerComponent } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.less']
})
export class ResetpasswordComponent implements OnInit {

  email: string;

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(public auth: AuthService, private toasterService: ToasterService, private spinner: Ng4LoadingSpinnerService) { }

  ngOnInit() {
  }

  resetPassword(){
    this.spinner.show();
    if(!this.email){
      let toast: Toast = {
        type: 'info',
        title: 'Bitte geben sie eine E-Mail-Adresse ein.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    } else {
      this.auth.resetPassword(this.email).then(()=>{
        this.spinner.hide();
      }).catch(err => {
        console.log(err);
        this.spinner.hide();
      });      
    } 
  }
}
