import { Component, OnInit } from '@angular/core';
import { DataproviderService } from '../provider/dataprovider.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { AuthService } from '../../providers/auth.service';

@Component({
  selector: 'app-mails',
  templateUrl: './mails.component.html',
  styleUrls: ['./mails.component.less']
})
export class MailsComponent implements OnInit {

  mails;

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public dataProvider: DataproviderService,
    public spinnerService: Ng4LoadingSpinnerService,
    public toasterService: ToasterService,
    public auth: AuthService,
  ) { }

  ngOnInit() {
    this.spinnerService.show();
    console.log('init');
    this.dataProvider.getMails().then((mails) => {
      this.mails = mails;
      console.log(mails);
      this.spinnerService.hide();
    }).catch((err) => {
      console.log(err);
      this.spinnerService.hide();
    });
  }

  /**
   * deletes the mail from the Array of mails
   * @param mail the specific mail to delete
   * @param i the index of the mail in the mailArray
   */
  deleteMail(mail, i){
    // mail = mail.title, mail.message, mail.type
    if(window.confirm('Jobcode wirklich löschen?')){
      this.spinnerService.show();
      this.dataProvider.deleteMail(mail.type).then(()=>{
        setTimeout(()=>{
          this.spinnerService.hide();
          let toast: Toast = {
            type: 'success',
            title: 'Jobcode erfolgreich gelöscht!',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.mails.splice(i,1);
        },2000);
      })
      .catch((err)=>{
        console.log(err);
      });
    } 
  }

}
