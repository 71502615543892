import { Component, OnInit } from '@angular/core';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from '../../providers/auth.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router'
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AngularFireAuth } from 'angularfire2/auth';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireStorage } from 'angularfire2/storage';
import { database } from 'firebase';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';

@Component({
  selector: 'app-einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.less']
})
export class EinstellungenComponent implements OnInit {

  user: any;
  form: FormGroup;
  downloadURL: string;
  link: Observable<string>;
  passwordOld: string;
  passwordRepeat: string;
  password: string;
  userPass: string;
  userMail: string;
  bankAdmins = new Array<any>();
  bankcheck: boolean = false;


  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 2,
    animation: 'slideUp'
  });

  constructor(public userProvider: UserProviderService, public auth: AuthService,
    private spinnerService: Ng4LoadingSpinnerService, private fb: FormBuilder, public storage: AngularFireStorage,
    public afDatabase: AngularFireDatabase, private toasterService: ToasterService, private router: Router,
    private afAuth: AngularFireAuth) {
    this.createForm();
    if (!this.user) {
      this.getData();
    }
  }

  ngOnInit() { }

  addUser() {
    this.auth.registerBankuser(this.userMail, this.userPass);
  }

  deleteUserMail(entry, i){
    this.afDatabase.database.ref('users/banken/' + this.auth.currentBank.userId).child('userEmails').orderByChild('email').equalTo(entry).once('value', snap =>{
      let data = snap.val();
      console.log('data: ', data);
      let dataKeys = Object.keys(data);
      dataKeys.forEach(entry => {
        console.log(data[entry]['pushId']);
        let mailRef = this.afDatabase.database.ref('users/banken/' + this.auth.currentBank.userId).child('userEmails').child(entry);
        mailRef.remove().then(() => {
          this.afDatabase.database.ref('users/adminList/').orderByChild('email').equalTo(data[entry]['email']).once('value', snap => {
            let mailNode = snap.val();
            let mailNodeKeys = Object.keys(mailNode);
            mailNodeKeys.forEach(entry => {
              let delRef = this.afDatabase.database.ref('users/adminList/').child(entry);
              delRef.remove().catch(err => console.log(err));
            });
          });
        }).catch(err => console.log(err));
      });
    });
    this.bankAdmins.splice(i, 1);
  }

  reAuthUser(credentials) {
    return this.afAuth.auth.currentUser.reauthenticateAndRetrieveDataWithCredential(credentials)
      .catch((error) => {
        console.log(error)
        // catch new password errors (to short etc);
        if (error.code == "auth/wrong-password") {
          let toast: Toast = {
            type: 'error',
            title: 'Das eingebene Passwort ist falsch.',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.spinnerService.hide();
        }
        if (error.code == "auth/weak-password") {
          let toast: Toast = {
            type: 'info',
            title: 'Das neue Passwort ist zu kurz',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.spinnerService.hide();
        }
      });
  }

  changePassword(oldpass, pass, pass2) {
    this.spinnerService.show();
    // feld leer
    if (!oldpass || !pass || !pass2) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus!',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    } // password + passrepeat not same
    else if (pass != pass2) {
      let toast: Toast = {
        type: 'info',
        title: 'Die Passwörter stimmen nicht überein!',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
    // felder ausgefüllt und pass = pass2 -> reauth with passold 
    else {
      //reauth with credential than update if all fields work
      this.auth.checkUserState().then(res => {
        if (!res) {
          //get correct email: 
          this.afAuth.authState.subscribe((auth) => {
            if (auth != null) {
              let email = this.afAuth.auth.currentUser.email;
              console.log(email);
              const credentials = firebase.auth.EmailAuthProvider.credential(
                this.user.email, this.passwordOld
              );
              this.reAuthUser(credentials)
                .then((reauth) => {
                  this.afAuth.auth.currentUser.updatePassword(pass)
                    .then(() => {
                      //alles gültig
                      let toast: Toast = {
                        type: 'success',
                        title: 'Ihr Passwort wurde geändert!',
                        showCloseButton: true
                      }
                      this.toasterService.pop(toast);
                      this.passwordOld = "";
                      this.passwordRepeat = "";
                      this.password = "";
                      this.spinnerService.hide();
                    });
                });
            }
          });
        } else {
          const credentials = firebase.auth.EmailAuthProvider.credential(
            this.user.email, this.passwordOld
          );
          this.reAuthUser(credentials)
            .then((reauth) => {
              this.afAuth.auth.currentUser.updatePassword(pass)
                .then(() => {
                  //alles gültig
                  let toast: Toast = {
                    type: 'success',
                    title: 'Ihr Passwort wurde geändert!',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                  this.passwordOld = "";
                  this.passwordRepeat = "";
                  this.password = "";
                  this.spinnerService.hide();
                });
            });
        }
      });
    }
  }

  createForm() {
    this.form = new FormGroup({
      company: new FormControl(),
      partner: new FormControl(),
      adress: new FormControl(),
      phone: new FormControl(),
      plz: new FormControl(),
      email: new FormControl(),
      city: new FormControl(),
      website: new FormControl(),
      country: new FormControl(),
      applicationsPerYear: new FormControl(),
      balance: new FormControl(),
    })
    this.userProvider.getUserData().then((user: any) => {
      this.form = this.fb.group({
        company: [user.company, Validators.required],
        partner: [user.partner, Validators.required],
        adress: [user.adress, Validators.required],
        phone: [user.phone, Validators.required],
        plz: [user.plz, Validators.required],
        email: [user.email, Validators.required],
        city: [user.city, Validators.required],
        website: [user.website],
        country: [user.country, Validators.required],
        applicationsPerYear: [user.applicationsPerYear],
        balance: [user.balance],
      });
    });
  }

  getData() {
    this.getUserData();
    this.spinnerService.show();
  }

  getUserData() {
    this.userProvider.getUserData().then((user: any) => {
      this.user = user;
      if (this.auth.adminUser) {
        let temp = new Array<any>();
        this.bankcheck = true;
        let emailKeys = Object.keys(user.userEmails);
        console.log(emailKeys);
        emailKeys.forEach(entry => {
          console.log(entry);
          if (user['userEmails'][entry]['email'] != user.email) {
            console.log((user['userEmails'][entry]['email']))
            temp.push(user['userEmails'][entry]['email']);
          }
        });
        this.bankAdmins = temp;
        console.log('temp: ', temp);
      } else {
        let temp = new Array<any>();
        this.bankcheck = true;
        let emailKeys = Object.keys(user.userEmails);
        console.log(emailKeys);
        emailKeys.forEach(entry => {
          console.log(entry);
          if (user['userEmails'][entry]['email'] != user.email) {
            console.log((user['userEmails'][entry]['email']))
            temp.push(user['userEmails'][entry]['email']);
          }
        });
        this.bankAdmins = temp;
        console.log('temp: ', temp);
      }

      this.spinnerService.hide();
    });
  }

  recreateFormData() {
    this.userProvider.getUserUpdateData().then((user: any) => {
      this.user = user;
      console.log(user);
      this.createForm();
      this.spinnerService.hide();
    })
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  updateData() { // userdaten bearbeiten
    this.spinnerService.show();
    let { company, partner, plz, city, country,
      email, website, balance, applicationsPerYear, adress, phone }
      = this.form.value;
    let userRef = this.afDatabase.database.ref("/users/banken/" + this.user.userId);
    let updateObject: any = {};

    if (company && (this.user.company != company)) {
      updateObject.company = company;
    }
    if (partner && (this.user.partner != partner)) {
      updateObject.partner = partner;
    }
    if (plz && (this.user.plz != plz)) {
      updateObject.plz = plz;
    }
    if (city && (this.user.city != city)) {
      updateObject.city = city;
    }
    if (country && (this.user.country != country)) {
      updateObject.country = country;
    }
    if (email && (this.user.email != email) && this.validateEmail(email)) {
      updateObject.email = email;
      // this.auth.changeMail(email);
    }
    if ((this.user.website != website)) {
      updateObject.website = website;
    }
    if ((this.user.balance != balance)) {
      updateObject.balance = balance
    }
    if (phone && (this.user.phone != phone)) {
      updateObject.phone = phone
    }
    if ((this.user.applicationsPerYear != applicationsPerYear)) {
      updateObject.applicationsPerYear = applicationsPerYear;
    }
    if (adress && (this.user.adress != adress)) {
      updateObject.adress = adress;
    }
    if (!this.form.valid) {
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }

    else if (updateObject && Object.keys(updateObject).length != 0) {
      userRef.once("value", snapshot => {
        userRef.update(updateObject);
        if (updateObject.company && updateObject.company != '') {
          this.updateDataRecursive(updateObject.company, this.user.userId).then(() => {
            let toast: Toast = {
              type: 'success',
              title: 'Profilinformationen erfolgreich geändert!',
              showCloseButton: true
            }
            this.toasterService.pop(toast);
            this.spinnerService.hide();
          }).catch(err => {
            console.log("err in recursive function updatedata: ", err);
          });
        }
      }).then(() => {
        this.recreateFormData();
      }).catch((err) => {
        console.log(err);
      })
    } else if (Object.keys(updateObject).length == 0) {
      let toast: Toast = {
        type: 'info',
        title: 'Sie haben keine Änderungen vorgenommen.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
      this.spinnerService.hide();
    }
  }

  updateDataRecursive(companyName, userId) {
    return new Promise((resolve, reject) => {
      let jobRef = this.afDatabase.database.ref('jobs').child(userId);
      jobRef.once("value", snap => {
        let jobs = snap.val();
        if (jobs) {
          let jobIds = Object.keys(jobs);
          jobIds.forEach(id => {
            let updateObject = {
              company: companyName
            };
            let updateRef = jobRef.child(id);
            updateRef.update(updateObject).then(() => {
              console.log("updated Jobs recursivly");
              resolve();
            }).catch((err) => {
              console.log('error in recursive job update: ', err);
              reject(err);
            });
          });
        } else {
          console.log("company has no jobs yet. do nothing.");
          resolve(null);
        }
      });
    });
  }

  uploadImage(event) {
    this.spinnerService.show();
    return new Promise(resolve => {
      let file = event.target.files[0];
      let filePath = 'images/' + this.user.userId + '/logo';
      let fileRef = this.storage.ref(filePath);
      let task = this.storage.upload(filePath, file);
      task.snapshotChanges().pipe(
        finalize(() => {
          (this.link = fileRef.getDownloadURL()),
            resolve();
        })
      ).subscribe();
    });
  }

  uploadFile(event) {
    this.uploadImage(event).then(() => {
      this.link.subscribe(link => {
        this.downloadURL = link;
        let userRef = this.afDatabase.database.ref("/users/banken/" + this.user.userId);
        let linkRef = userRef.child('logoUrl');
        linkRef.once("value", snapshot => {
          linkRef.set(this.downloadURL);
          let toast: Toast = {
            type: 'success',
            title: 'Logo erfolgreich hochgeladen',
            showCloseButton: true
          }
          this.toasterService.pop(toast);
          this.recreateFormData();
          this.spinnerService.hide();
        });
      });
    });
  }

}
