import { Component, OnInit } from '@angular/core';
import { NoopAnimationPlayer } from '@angular/animations';
import { JobProviderService } from '../../providers/job-provider.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from '../../providers/auth.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { database } from 'firebase';

import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { getOrCreateNodeInjector } from '@angular/core/src/render3/di';

declare var google: any;

@Component({
  selector: 'app-jobcode-create',
  templateUrl: './jobcode-create.component.html',
  styleUrls: ['./jobcode-create.component.less']
})
export class JobcodeCreateComponent implements OnInit {

  question4Set: boolean;
  question5Set: boolean;
  status: string;
  form: FormGroup;
  public qcount: number;
  containers = [];
  jobNavPar;
  job: any;
  user: any;
  question4 = '';
  question5 = '';
  lastUpdate: any;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public userProvider: UserProviderService,
    private jobProvider: JobProviderService,
    public afDatabase: AngularFireDatabase,
    public auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    public toasterService: ToasterService,
    private gMapsApiLoader: MapsAPILoader
  ) {
    this.status = 'true';

    this.createForm().then(() => {
      this.qcount = 3;
      this.toasterService = toasterService;
      this.activeRoute.params.subscribe(param => {
        this.jobNavPar = param.jobId;
        if (this.jobNavPar != "" && this.jobNavPar) {
          this.spinnerService.show();
          this.jobProvider.getJobWithId(this.jobNavPar).then((job: any) => {
            this.job = job;
            if (job.question4) {
              this.qcount = 4;
            }
            if (job.question5) {
              this.qcount = 5;
            }
            this.status = this.job.status;
            this.form.patchValue({ status: this.status });
            this.spinnerService.hide();
          });
        }
      });
    });
  }

  ngOnInit() {
  }

  addQuestion() {
    this.qcount = this.qcount + 1;
    if (this.qcount <= 5) {
      if (this.qcount == 4) {
        this.question4Set = true;
      }
      if (this.qcount == 5) {
        this.question5Set = true;
      }
      this.containers.push(this.qcount);
    }
  }

  getLatLng(address) {
    return new Promise((resolve, reject) => {
      this.gMapsApiLoader.load().then(() => {
        let geocode = new google.maps.Geocoder();
        geocode.geocode({ 'address': address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log('Success - ', results);
            //get lat lng from results and resolve it
            let lat = results[0].geometry.location.lat();
            let lng = results[0].geometry.location.lng();
            resolve({ lat, lng });
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            resolve(null);
          }
        });
      });
    });
  }

  delQuestion(index) {
    console.log("index ", index);
    if (this.qcount > 3) {
      if (this.qcount == 4) {
        this.question4Set = false;
        this.form.patchValue({ question4: '' });
      } else {
        // qcount = 5 | frage 4 löschen -> frage4 = frage 5 -> frage 5 löschen
        if (index == 0) {
          let a = this.form.get('question5');
          let b = a.value
          this.form.patchValue({ question4: b });
          this.form.patchValue({ question5: '' })
        }
        if (index == 1) {
          this.question5Set = false;
          this.form.patchValue({ question5: '' });
        }
      }
      this.containers.pop();
      this.qcount = this.qcount - 1;
    }
  }

  delQuestNr(jobId, question) {
    if (window.confirm('Frage wirklich löschen?')) {
      this.jobProvider.delQuestNr(jobId, question).then(() => {
        if (this.job.question4 == question) {
          if (this.job.question5) {
            this.job.question4 = this.job.question5;
            this.job.question5 = null;
            this.qcount = 4;
          } else {
            this.job.question4 = null;
            this.qcount = 3;
            console.log(this.qcount)
          }
        }
        if (this.job.question5 == question) {
          this.job.question5 = null;
          this.qcount = 4;
        }
        let toast: Toast = {
          type: 'success',
          title: 'Frage gelöscht!',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      });
    }
  }

  createForm() {
    return new Promise<any>((resolve, reject) => {
      this.form = new FormGroup({
        jobTitle: new FormControl(),
        description: new FormControl(),
        plz: new FormControl(),
        city: new FormControl(),
        country: new FormControl({ value: 'Deutschland', disabled: true }),
        aufrufe: new FormControl(),
        bewerbungen: new FormControl(),
        status: new FormControl(),
        question1: new FormControl(),
        question2: new FormControl(),
        question3: new FormControl(),
        question4: new FormControl(),
        question5: new FormControl(),
        userId: new FormControl(),
        jobId: new FormControl(),
        company: new FormControl(),
        partner: new FormControl(),
      });
      this.userProvider.getUserData().then((user: any) => {
        this.user = user;
        this.form = this.fb.group({
          jobTitle: ['', Validators.required],
          description: ['', Validators.required],
          plz: ['', Validators.required],
          city: ['', Validators.required],
          country: ['Deutschland', Validators.required],
          aufrufe: ['0', Validators.required],
          bewerbungen: ['0', Validators.required],
          status: [this.status, Validators.required],
          question1: ['', Validators.required],
          question2: ['', Validators.required],
          question3: ['', Validators.required],
          question4: ['', Validators.required],
          question5: ['', Validators.required],
          userId: [user.userId, Validators.required],
          jobId: ['', Validators.required],
          company: [user.company, Validators.required],
          partner: [user.partner, Validators.required],
        });
        resolve();
      });
    });
  }

  setCount() {
    this.qcount += 1;
    console.log(this.qcount);
  }

  makeId() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  createJob(existjobId: any) {
    if (!existjobId) { //create jobobject
      let { jobTitle, description, plz, city, country, bewerbungen, aufrufe, companyMail,
        question1, question2, question3, question4, question5, logoUrl, userId, status, jobId, company, partner }
        = this.form.value;
      console.log("status: " + status)
      if (!userId) {
        userId = this.userProvider.user.userId;
      }
      if (!logoUrl) {
        logoUrl = this.userProvider.user.logoUrl ? this.userProvider.user.logoUrl : '';
      }
      if (!bewerbungen) {
        bewerbungen = 0;
      }
      if (!aufrufe) {
        aufrufe = 0;
      }
      if (!jobTitle || !description || !plz || !city || !country || !question1 || !question2 || !question3 || !status) {
        let toast: Toast = {
          type: 'info',
          title: 'Bitte füllen sie alle Felder aus.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      } else {
        let jobId = this.makeId();
        // generate latlong:
        this.getLatLng(plz + " " + city + ", " + country).then((response: { lat: string, lng: string }) => {
          if (response != null) {
            if (response.lat == "51.165691" && response.lng == "10.451526000000058") {
              let toast: Toast = {
                type: 'info',
                title: 'Wir konnten die Adresse nicht ermitteln, bitte prüfen sie Plz und Ort.',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
            } else {
              let formRequest = Object({
                jobTitle: jobTitle,
                description: description,
                plz: plz,
                city: city,
                companyMail: this.user.email,
                country: country,
                bewerbungen: bewerbungen,
                aufrufe: aufrufe,
                question1: question1,
                question2: question2,
                question3: question3,
                userId: userId,
                logoUrl: logoUrl,
                status: status,
                jobId: jobId,
                timestamp: Date.now(),
                lat: response.lat,
                lng: response.lng,
                company: company,
                partner: partner,
              });
              if (this.question4Set) {
                formRequest.question4 = question4;
              }
              if (this.question5Set) {
                formRequest.question5 = question5;
              }
              this.jobProvider.createJob(formRequest, userId).then(() => {
                this.form.reset();
                this.form.patchValue({ status: this.status });
                this.form.patchValue({ country: 'Deutschland' });
                this.form.patchValue({ company: this.user.company });
                this.form.patchValue({ partner: this.user.partner });
                let toast: Toast = {
                  type: 'success',
                  title: 'Jobcode erfolgreich erstellt!',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
              });
            }
          } else {
            console.log("getLatLng error");
            let toast: Toast = {
              type: 'warning',
              title: 'Es gab einen Fehler bei der Adressbestimmung, bitte versuchen Sie es erneut.',
              showCloseButton: true
            }
            this.toasterService.pop(toast);
          }
        });
      }
    } else if (existjobId) { // create updateobject
      console.log('create with existing job');
      let { jobTitle, description, plz, city, country,
        question1, question2, question3, question4, question5, logoUrl, userId, jobId, status }
        = this.form.value;
      let updateObject: any = {};

      console.log('form value: ', this.form.value, ' lastupdate values: ', this.lastUpdate);
      // Bewerbungen und Aufrufe werden nicht geändert
      if (jobTitle) {
        updateObject.jobTitle = jobTitle;
      }
      if (logoUrl) {
        if (this.job.logoUrl != logoUrl) {
          updateObject.logoUrl = this.userProvider.user.logoUrl;
        }
      }
      if (description) {
        updateObject.description = description;
      }
      if (plz) {
        updateObject.plz = plz;
      }
      if (city) {
        updateObject.city = city;
      }
      //21.11.19 country is set already and fixed. no need to check here
      /* if (country) {
        console.log(country);
        updateObject.country = country;
      } */
      if (this.job.status != status) {
        updateObject.status = status;
        // moved this to then. 21.11.2019
        // this.jobProvider.updateJobCount(userId, status);
      }
      if (question1) {
        updateObject.question1 = question1;
      }
      if (question2) {
        updateObject.question2 = question2;
      }
      if (question3) {
        updateObject.question3 = question3
      }
      if (question4) {
        updateObject.question4 = question4;
      }
      if (question5) {
        updateObject.question5 = question5;
      }
      if (updateObject && Object.keys(updateObject).length != 0) {
        console.log('changes part- object.key length: ', Object.keys(updateObject).length, ' - updateobject: ', updateObject);
        // 21.11.19: removed updateObject.country cause its fix anyways. 
        if (updateObject.plz || updateObject.city) {
          console.log('in 1. get lat long');
          this.getLatLng(plz + " " + city + ", " + country).then((response: { lat: string, lng: string }) => {
            if (response.lat == "51.165691" && response.lng == "10.451526000000058") {
              let toast: Toast = {
                type: 'info',
                title: 'Wir konnten die Adresse nicht ermitteln, bitte prüfen sie Plz und Ort.',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
            } else {
              updateObject.lat = response.lat;
              updateObject.lng = response.lng;
              this.jobProvider.updateJob(updateObject, userId, existjobId).then(() => {
                if (this.job.status != status) {
                  this.jobProvider.updateJobCount(userId, status).then(() => {
                    let toast: Toast = {
                      type: 'success',
                      title: 'Jobcode erfolgreich geändert!',
                      showCloseButton: true
                    }
                    this.toasterService.pop(toast);
                    this.recreateFormData(existjobId);
                  });
                } else {
                  let toast: Toast = {
                    type: 'success',
                    title: 'Jobcode erfolgreich geändert!',
                    showCloseButton: true
                  }
                  this.toasterService.pop(toast);
                  this.recreateFormData(existjobId);
                }
              }).catch((err) => {
                console.log(err);
              });
            }
          }).catch((err) => {
            console.log(err);
          });
        } else {
          console.log('update#2: ', updateObject);
          this.jobProvider.updateJob(updateObject, userId, existjobId).then(() => {
            if (this.job.status != status) {
              this.jobProvider.updateJobCount(userId, status).then(() => {
                let toast: Toast = {
                  type: 'success',
                  title: 'Jobcode erfolgreich geändert!',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);              
                this.recreateFormData(existjobId);
              });
            } else {
              let toast: Toast = {
                type: 'success',
                title: 'Jobcode erfolgreich geändert!',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
              this.recreateFormData(existjobId);
            }
          }).catch((err) => {
            console.log(err);
          });
        }
      } else {
        let toast: Toast = {
          type: 'info',
          title: 'Sie haben keine Änderungen vorgenommen.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      }
    }
    //etwas ist schiefgegangen
    else {
      let toast: Toast = {
        type: 'info',
        title: 'Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    }
  }

  recreateFormData(pushId) {
    console.log(pushId, ' <-job of user -> ', this.job.userId);
    this.jobProvider.getJobWithId(this.jobNavPar).then((job: any) => {
      this.job = job;
      if (job.question4) {
        this.qcount = 4;
      }
      if (job.question5) {
        this.qcount = 5;
      }
      this.status = this.job.status;
      this.form.patchValue({ status: this.status });
      this.spinnerService.hide();
    });
    /* this.jobProvider.getJobWithId(pushId).then((job: any) => {
      console.log(job);
      this.job = job;
      this.status = job.status;
      this.form.patchValue({ status: job.status });
      if (job.question4 && job.question4 != '') {
        this.question4 = job.question4;
      }
      if (job.question5 && job.question5 != '') {
        this.question5 = job.question5;
      }
      // 21.11.19: removed that call, cause values from successfull update are shown anyway and this will update the values of this.form.value if called.
      //this.recreateForm();
      this.spinnerService.hide();
    }); */
  }

  recreateForm() {
    this.form = this.fb.group({
      jobTitle: [this.job.jobTitle, Validators.required],
      description: [this.job.description, Validators.required],
      plz: [this.job.plz, Validators.required],
      city: [this.job.city, Validators.required],
      country: [this.job.country, Validators.required],
      aufrufe: ['0', Validators.required],
      bewerbungen: ['0', Validators.required],
      status: [this.job.status, Validators.required],
      question1: [this.job.question1, Validators.required],
      question2: [this.job.question2, Validators.required],
      question3: [this.job.question3, Validators.required],
      question4: [this.question4, Validators.required],
      question5: [this.question5, Validators.required],
      userId: [this.job.userId, Validators.required],
      jobId: [this.job.jobId, Validators.required],
      company: [this.job.company, Validators.required]
    });
  }
}