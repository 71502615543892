import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { JobcodeComponent } from './pages/jobcode/jobcode.component';
import { JobcodeCreateComponent } from './pages/jobcode-create/jobcode-create.component';
import { BewerbungenComponent } from './pages/bewerbungen/bewerbungen.component';
import { EinstellungenComponent } from './pages/einstellungen/einstellungen.component';
import { SupportComponent } from './pages/support/support.component';
import { SidebarComponent } from './pages/sidebar/sidebar.component';
import { BewerbungenDetailsComponent } from './pages/bewerbungen-details/bewerbungen-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'


import { ToasterModule, ToasterService, ToasterConfig } from 'angular2-toaster';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';

import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from 'angularfire2/auth';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorage } from 'angularfire2/storage';
import { environment } from '../environments/environment';


import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from 'src/app/providers/auth.service';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';

import { BankenVerwaltenComponent } from './admin/banken-verwalten/banken-verwalten.component';
import { BewerberVerwaltenComponent } from './admin/bewerber-verwalten/bewerber-verwalten.component';
import { BewerbungenVerwaltenComponent } from './admin/bewerbungen-verwalten/bewerbungen-verwalten.component';
import { JobcodesVerwaltenComponent } from './admin/jobcodes-verwalten/jobcodes-verwalten.component';
import { MailsComponent } from './admin/mails/mails.component';
import { CreatebankComponent } from './admin/createbank/createbank.component';
import { BankDetailComponent } from './admin/bank-detail/bank-detail.component';
import { SearchPipe, SearchPartnerPipe, SearchPrePipe, SearchSurPipe, SearchApplicantPipe, SearchApplicantJCPipe, SearchApplicantJtPipe, SearchApplicantPrePipe } from './pipe/search.pipe';
import { JobcodePipe, JobBankPipe, JobTitlePipe, JobStatusPipe } from './pipe/jobcode.pipe';
import { JobDetailComponent } from './admin/job-detail/job-detail.component';
import { PushComponent } from './admin/push/push.component';
import { EmailComponent } from './admin/email/email.component';
import { BewerberDetailComponent } from './admin/bewerber-detail/bewerber-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DashboardComponent,
    JobcodeComponent,
    JobcodeCreateComponent,
    BewerbungenComponent,
    EinstellungenComponent,
    SupportComponent,
    SidebarComponent,
    BewerbungenDetailsComponent,
    ResetpasswordComponent,
    BankenVerwaltenComponent,
    BewerberVerwaltenComponent,
    BewerbungenVerwaltenComponent,
    JobcodesVerwaltenComponent,
    MailsComponent,
    CreatebankComponent,
    BankDetailComponent,
    SearchPipe,
    SearchApplicantPipe,
    SearchApplicantJCPipe,
    SearchApplicantJtPipe,
    SearchApplicantPrePipe,
    SearchPartnerPipe,
    SearchPrePipe,
    SearchSurPipe,
    JobcodePipe,
    JobBankPipe, 
    JobTitlePipe, 
    JobStatusPipe,
    JobDetailComponent,
    PushComponent,
    EmailComponent,
    BewerberDetailComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    Ng4LoadingSpinnerModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase, 'myfugo'), 
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey
    }), 
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    ToasterModule.forRoot(),
  ],
  providers: [ 
    UserProviderService,
    AuthService,
    AngularFireStorage,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
