import { Injectable } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class JobProviderService {

  constructor(
    private afDb: AngularFireDatabase,
    public afAuth: AngularFireAuth,
    public authService: AuthService,
  ) { }

  createJob(jobObject: any, userId: any) {
    return new Promise((resolve, reject) => {
      let jobRef = this.afDb.database.ref("/jobs/" + userId + '/');
      let push = jobRef.push();
      let pushId = push.key;
      jobObject.pushId = pushId;
      push.set(jobObject);
      resolve();
    }).then(() => {
      this.createJobCount(userId, jobObject.status);
      let change = 'new';
      this.overallJobCount(jobObject.status, change);
    }).catch((error) => {
      // console.log(error);
    });
  }

  createJobCount(userId: any, status: any) {
    let userRef = this.afDb.database.ref('/users/banken/' + userId);
    let statisticRef = userRef.child('statistic')
    let updateObject: any = {};
    // Jobcounts für dashboard
    statisticRef.once("value", snapshot => {
      let stats = snapshot.val();
      let activeJobs: number;
      let inactiveJobs: number;
      let jobCount: number;
      if (status == 'true') {
        activeJobs = stats.activeJobs + 1;
        inactiveJobs = stats.inactiveJobs;
      }
      if (status == 'false') {
        inactiveJobs = stats.inactiveJobs + 1;
        activeJobs = stats.activeJobs;
      }
      jobCount = inactiveJobs + activeJobs;
      updateObject.activeJobs = activeJobs;
      updateObject.inactiveJobs = inactiveJobs;
      updateObject.jobCount = jobCount;
      statisticRef.update(updateObject)
    });
  }

  deleteJob(job: any) {
    return new Promise((resolve, reject) => {

      let userId = job.userId;
      let jobRef = this.afDb.database.ref("/jobs/" + userId);
      let userRef = this.afDb.database.ref('/users/banken/' + userId);
      let statisticRef = userRef.child('statistic');
      let updateObject: any = {};
      // Jobcounts für dashboard
      statisticRef.once("value", snapshot => {
        let stats = snapshot.val();
        let activeJobs;
        let inactiveJobs;
        let jobCount;
        if (job.status == 'true') {
          activeJobs = stats.activeJobs - 1;
          inactiveJobs = stats.inactiveJobs;
        } else {
          inactiveJobs = stats.inactiveJobs - 1;
          activeJobs = stats.activeJobs;
        }
        jobCount = inactiveJobs + activeJobs;
        updateObject.activeJobs = activeJobs;
        updateObject.inactiveJobs = inactiveJobs;
        updateObject.jobCount = jobCount;
        statisticRef.update(updateObject)
      });
      jobRef.child(job.pushId).remove();


      this.overallJobCount(job.status, 'delete');
      resolve();
    })
  }

  delQuestNr(jobId, question) {
    return new Promise((resolve, reject) => {
      this.afAuth.authState.subscribe((auth) => {
        if (auth != null) {
          let userId = this.afAuth.auth.currentUser.uid;
          let jobRef = this.afDb.database.ref("/jobs/" + userId).child(jobId);
          this.getJobWithId(jobId).then((questItem: any) => {
            if (questItem.question4 == question && questItem.question5) {
              questItem.question4 = questItem.question5;
              let questUpdate: any = {}
              jobRef.once("value", snapshot => {
                let quest = snapshot.val();
                questUpdate.question4 = quest.question5;
                jobRef.update(questUpdate);
                jobRef.child('question5').remove();
              });
            } else {
              jobRef.child('question4').remove();
            }
            if (questItem.question5 == question) {
              jobRef.child('question5').remove();
            }
          });
        }
      }
      );
      resolve();
    });
  }

  getAllJobs() {
    return new Promise((resolve, reject) => {
      let jobRef = this.afDb.database.ref('jobs').orderByChild('timestamp').once("value", snap => {
        let allJobUsers = snap.val();
        let jobUserKeys = Object.keys(allJobUsers);
        let temp = new Array<any>();
        let jobKeys: Array<any>;
        jobUserKeys.forEach(jobUserkey => {
          if (!(jobUserkey == 'stats')) {
            jobKeys = Object.keys(allJobUsers[jobUserkey]);
            jobKeys.forEach(jobKey => {
              temp.push(allJobUsers[jobUserkey][jobKey]);
            });
            temp.sort((val1, val2) => {
              return val2.timestamp - val1.timestamp;
            });
          }
        });
        resolve(temp);
      });
    });
  }

  getJobCount() {
    return new Promise((resolve, reject) => {
      let jobRef = this.afDb.database.ref('jobs').child('stats');
      jobRef.on("value", snapshot => {
        let jobs = snapshot.val();
        let active = jobs.activeJobs;
        let inactive = jobs.inactiveJobs;
        let temp = [active, inactive]
        resolve(temp);
      });
    });
  }

  getJobs() {
    return new Promise((resolve, reject) => {
      if (this.authService.adminUser) {
        // console.log('2nd level user');
        let userId = this.authService.bankId;
        let jobRef = this.afDb.database.ref("/jobs/" + userId);
        jobRef.once("value", snapshot => {
          let job = snapshot.val();
          if (job) {
            let keys = Object.keys(job);
            let temp = new Array<any>();
            keys.forEach(key => {
              if (job[key].pushId != "" && job[key].pushId) {
                temp.push(job[key]);
              }
            });
            temp.sort((val1, val2) => {
              return val2.timestamp - val1.timestamp;
            });
            resolve(temp);
          } else {
            resolve();
          }
        }, error => {
          // console.log(error);
        });
      }
      this.afAuth.authState.subscribe(
        (auth) => {
          if (auth != null) {
            let userId = this.afAuth.auth.currentUser.uid;
            let jobRef = this.afDb.database.ref("/jobs/" + userId);
            jobRef.once("value", snapshot => {
              let job = snapshot.val();
              if (job) {
                let keys = Object.keys(job);
                let temp = new Array<any>();
                keys.forEach(key => {
                  if (job[key].pushId != "" && job[key].pushId) {
                    temp.push(job[key]);
                  }
                });
                temp.sort((val1, val2) => {
                  return val2.timestamp - val1.timestamp;
                });
                resolve(temp);
              } else {
                resolve();
              }
            }, error => {
              // console.log(error);
            });
          }
        });
    });
  }

  getJobWithId(jobId) {
    return new Promise((resolve, reject) => {
      if (jobId.userId) { //job for adminaccount -> jobid bei admin anfrage = {{jobid:xxx},{userid:xxx}}
        let userId = jobId.userId;
        let jobDbId = jobId.jobId;
        let jobRef = this.afDb.database.ref("/jobs/" + userId).child(jobDbId);
        jobRef.once("value", snap => {
          let job = snap.val();
          resolve(job);
        }, error => {
          console.log(error);
          reject(error);
        });
      } else { //job for useraccount 
        this.afAuth.authState.subscribe(
          (auth) => {
            if (auth != null) {
              let userId = this.afAuth.auth.currentUser.uid;
              let jobRef = this.afDb.database.ref("/jobs/" + userId).child(jobId);
              jobRef.once("value", snapshot => {
                let job = snapshot.val();
                resolve(job);
              }, error => {
                console.log(error);
                reject(error);
              });
            }
          });
      }
    });
  }

  overallJobCount(status, change: any) {
    let jobCountRef = this.afDb.database.ref('jobs').child('stats');
    // console.log('status: ', status, ' change type: ', change);
    jobCountRef.once("value", snap => {
      let statistic = snap.val();
      let activeJobs = statistic.activeJobs;
      let inactiveJobs = statistic.inactiveJobs;
      let countObject: any = {};
      if (change == 'new') {
        if (status == 'true') {
          activeJobs += 1;
        }
        if (status == 'false') {
          inactiveJobs += 1;
        }
        countObject.activeJobs = activeJobs;
        countObject.inactiveJobs = inactiveJobs;
        jobCountRef.update(countObject);
      } else if (change == 'update') {
        if (status == 'true') {
          activeJobs = activeJobs + 1;
          inactiveJobs = inactiveJobs - 1;
        }
        if (status == 'false') {
          activeJobs = activeJobs - 1;
          inactiveJobs = inactiveJobs + 1;
        }
        countObject.activeJobs = activeJobs;
        countObject.inactiveJobs = inactiveJobs;
        jobCountRef.update(countObject);
      } else if (change == 'delete') {
        if (status == 'true') {
          activeJobs = activeJobs - 1;
        }
        if (status == 'false') {
          inactiveJobs = inactiveJobs - 1;
        }
        countObject.activeJobs = activeJobs;
        countObject.inactiveJobs = inactiveJobs;
        jobCountRef.update(countObject);
      } else if (change.statistic) {
        // TODO: get deleted user node || get user as changeobject
        countObject.activeJobs = activeJobs - change.statistic.activeJobs;
        countObject.inactiveJobs = inactiveJobs - change.statistic.inactiveJobs;
        jobCountRef.update(countObject);
      }
    });
  }

  updateJob(jobObject, userId, existjobId) {
    return new Promise((resolve, reject) => {
      let jobUpdateRef = this.afDb.database.ref("/jobs/" + userId).child(existjobId);
      jobUpdateRef.once("value", snapshot => {
        jobUpdateRef.update(jobObject);
        if (jobObject.question4 = "") {
          jobUpdateRef.child('question4').remove()
        }
        if (jobObject.question5 = "") {
          jobUpdateRef.child('question5').remove()
        }
      }).then(() => {
        resolve();
      }).catch((err) => {
        // console.log('err in update Job: ', err);
        reject(err);
      });
    });
  }

  updateJobCount(userId: any, status: any) {
    // console.log('update jobcount -> status: ', status);
    return new Promise((resolve, reject) => {
      let userRef = this.afDb.database.ref('/users/banken/' + userId);
      let statisticRef = userRef.child('statistic')
      let userUpdateObject: any = {};
      let change = 'update';
      statisticRef.once("value", snapshot => {
        let stats = snapshot.val();
        // console.log('stats: ', stats);
        let activeCurrent = stats.activeJobs;
        let inactiveCurrent = stats.inactiveJobs;
        console.log('active: ', activeCurrent, ' - inactive: ', inactiveCurrent);
        let activeJobs: number;
        let inactiveJobs: number;
        if (status == 'true') {
          activeJobs = stats.activeJobs + 1;
          if ((stats.inactiveJobs - 1) >= 0) {
            inactiveJobs = stats.inactiveJobs - 1;
          } else inactiveJobs = stats.inactiveJobs;
        }
        if (status == 'false') {
          console.log('status false: active before: ', stats.activeJobs);
          inactiveJobs = stats.inactiveJobs + 1;
          if ((stats.activeJobs - 1) >= 0) {

            activeJobs = stats.activeJobs - 1;
            console.log('status false: active after: ', activeJobs);

          } else activeJobs = stats.activeJobs;
        }
        console.log('status false: active after: ', activeJobs);
        userUpdateObject.activeJobs = activeJobs;
        userUpdateObject.inactiveJobs = inactiveJobs;
        console.log('userUpdateObject:', userUpdateObject); //active undefined if 0 und nicht geändert
        statisticRef.update(userUpdateObject).then((res) => {
          // console.log('update success. res: ', res);
          // console.log('overallJobcount: ', status, change);
          this.overallJobCount(status, change);
          resolve();
        });
      });

    });
  }

}
