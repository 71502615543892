import { Component, OnInit } from '@angular/core';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from 'src/app/providers/auth.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { AngularFireDatabase } from 'angularfire2/database';
import { cpus } from 'os';
import { DataproviderService } from '../../admin/provider/dataprovider.service';


@Component({
  selector: 'app-bewerbungen-details',
  templateUrl: './bewerbungen-details.component.html',
  styleUrls: ['./bewerbungen-details.component.less']
})
export class BewerbungenDetailsComponent implements OnInit {

  applications: any;
  application: any;
  comment: string;
  comments: any;
  navData: any;
  admin;

  constructor(
    public userProvider: UserProviderService,
    public auth: AuthService,
    private spinnerService: Ng4LoadingSpinnerService,
    private afDatabase: AngularFireDatabase,
    public dataService: DataproviderService,
  ) {
    this.navData = this.userProvider.applicationNavData;
    this.getAppData();
    this.getAdmin();
    console.log('navData: ', this.navData);
  }

  ngOnInit() {
  }

  deleteComment(item, index) {
    console.log(item);
    if (window.confirm('Kommentar wirklich löschen?')) {
      this.userProvider.deleteComment(this.application, item).then(() => {
        this.comments.splice(index, 1);
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  getAdmin() {
    this.dataService.getAdmin().then((admin) => {
      this.admin = admin;
    });
  }

  getAppData() {
    this.spinnerService.show();
    this.userProvider.getApplicationWithId(this.navData.pushId, this.navData.userId).then((application: any) => {
      this.application = application;
      console.log(this.application)
      if (this.application.comments) {
        let keys = Object.keys(this.application.comments);
        let temp = new Array<any>();
        keys.forEach(commentKey => {
          temp.push(application.comments[commentKey]);
        })
        console.log(temp);

        this.comments = temp;
      }
      this.spinnerService.hide();
    });
  }

  saveComment() {
    this.spinnerService.show();
    console.log("pushId: ", this.navData.pushId);
    if (this.comment && this.comment != null) {
      let commentRef = this.afDatabase.database.ref('applications').child(this.navData.userId).child(this.navData.pushId).child('comments');
      let push = commentRef.push();
      let pushId = push.key;
      let date = new Date().toLocaleString();
      push.set({
        comment: this.comment,
        date: date,
        pushId: pushId,
      }).then(() => {
        this.getAppData();
        this.spinnerService.hide();
      }).catch((err) => {
        console.log(err);
        this.spinnerService.hide();
      });
      this.comment = "";
    }
    this.spinnerService.hide();
  }

}
