import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from '../../providers/auth.service';
import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';

import { AngularFireDatabase } from 'angularfire2/database';
import { database } from 'firebase';
import { Placeholder } from '@angular/compiler/src/i18n/i18n_ast';
import { Promise, reject } from 'q';
import { DataproviderService } from '../../admin/provider/dataprovider.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.less']
})
export class SupportComponent implements OnInit {

  company: string;
  phone: string;
  email: string;
  partner: string;
  message: string;

  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  form: FormGroup;
  admin: any;

  constructor(
    public userProvider: UserProviderService,
    public toasterService:ToasterService,
    public fb: FormBuilder,
    public afDatabase: AngularFireDatabase,
    public auth: AuthService,
    private dataService: DataproviderService,
  ) {  }

  ngOnInit() {
    this.getData();
    this.createForm();
  }


  getData(){
    this.dataService.getAdmin().then((admin) =>{
      this.admin = admin;      
    });
  }


  createForm(){
    this.form = new FormGroup({
      company: new FormControl(),
      partner: new FormControl(),
      phone: new FormControl(),
      email: new FormControl(),
      message: new FormControl()
    })
    this.userProvider.getUserData().then((user:any) => {
      this.form = this.fb.group({
        company: [user.company],
        partner: [user.partner],
        phone: [user.phone],
        email: [user.email],
        message: ['']
      });
    });
  }

  onSubmit() {
    let {company, partner, phone, email, message} = this.form.value;
    if(!company || company === "" || !partner || partner ==="" || !phone || phone === "" || !email || email === ""|| !message || message === ""){
      let toast: Toast = {
        type: 'info',
        title: 'Bitte füllen sie alle Felder aus.',
        showCloseButton: true
      }
      this.toasterService.pop(toast);
    } else {
      if(!this.validateEmail(email)){
        let toast: Toast = {
          type: 'info',
          title: 'Bitte geben sie eine korrekte E-Mail Adresse an.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      } else {
        let date = Date();
        let html = `
          <div>Von: Partner: ${partner} - Firma: ${company}</div>
          <div>Telefon: <a href="tel:${phone}">${phone}</a></div>
          <div>E-Mail: <a href="mailto:${email}">${email}</a></div>
          <div>Datum: ${date}</div>
          <div>Nachricht: ${message}</div>
        `;
        let formRequest = { company, partner, phone, email, message, date, html };
        let formReset = { company, partner, phone, email}
        this.afDatabase.list('/forms/').push(formRequest);
        let toast: Toast = {
          type: 'success',
          title: 'Nachricht gesendet.',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
        this.form.reset(formReset);
      }
    }
  }

  validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


}


