import { UserProviderService } from './../../providers/user-provider.service';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { DataproviderService } from '../provider/dataprovider.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bewerbungen-verwalten',
  templateUrl: './bewerbungen-verwalten.component.html',
  styleUrls: ['./bewerbungen-verwalten.component.less']
})
export class BewerbungenVerwaltenComponent implements OnInit {

  applications;
  searchText;

  constructor(
    public auth: AuthService,
    public dataProvider: DataproviderService,
    private router: Router,
    public userProvider: UserProviderService,
  ) {
    this.dataProvider.getBewerbungenAdmin().then((applications: Array<any>) => {
      if (applications) {
        this.applications = applications;
      }
    })
  }

  ngOnInit() {
  }

  /**
   * deleteBewerbung(application, index) - deletes the selected application
   * @param application the selected application to delete
   * @param index the index of the application
   */
  deleteBewerbung(application, index) {
    console.log("application: " + application);
    console.log("index: " + index);
    if (window.confirm('Application wirklich löschen?')) {
      this.dataProvider.deleteApplicationStorage(application).then(() => {
        this.dataProvider.deleteApplication(application).then(() => {
          this.applications.splice(index, 1);
          console.log("Application gelöscht")
        }).catch((err) => {
          console.log(err);
        });
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  /**
   * toApplication(application) - 
   * @param application opens the application
   */
  toApplication(application) {
    console.log(application)
    this.userProvider.applicationNavData = application;
    this.router.navigate(['bewerbungen-details']);
  }

}
