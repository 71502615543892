import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from 'angularfire2/auth';
import { AuthService } from '../app/providers/auth.service';
import { Ng4LoadingSpinnerService} from 'ng4-loading-spinner';
import { UserProviderService } from '../app/providers/user-provider.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  status: boolean;
  private user: Observable<firebase.User>;

  constructor(private router: Router, private authService: AuthService,private afAuth: AngularFireAuth, 
    public spinnerService: Ng4LoadingSpinnerService, public userService: UserProviderService ){ }

   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let user = localStorage.getItem('user');
      let admin = localStorage.getItem('admin');
      if(user != null || admin != null){
        return true;  
      } else { 
        console.log('You have to be logged in to see this');
        this.router.navigate(['/']);
        return false;
      }  
  }

}




/* this.user = this.afAuth.authState;
      this.user.pipe(user => {
        console.log(user)
        if(user) {
          this.status = true;          
        } else {
          this.status = false;
        }
      })
      console.log(this.status);
      if( this.status){
        return true;
      } else {
        return false;
      } */