import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../providers/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import { UserProviderService } from '../../providers/user-provider.service';

import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  email: string;
  password: string;
  user: any;
  
  public config1 : ToasterConfig = new ToasterConfig({
    positionClass: 'toast-bottom-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(public auth: AuthService, public afAuth: AngularFireAuth, public userProvider: UserProviderService, private router: Router) { 
    
  }

  ngOnInit() {
    console.log('login page is laoded.')
  }

  login(){
    this.auth.login(this.email, this.password).then(() => {
      this.router.navigate(['dashboard'])
    });
  }

}
