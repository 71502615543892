import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jobcode'
})
export class JobcodePipe implements PipeTransform {

  transform(jobcodes: any, searchText: any): any {
    if(searchText == null) {
      return jobcodes;
    } else {
      return jobcodes.filter((job) => {
        return job.jobId.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'jobtitle'
})
export class JobTitlePipe implements PipeTransform {

  transform(jobtitles: any, searchText: any): any {
    if(searchText == null) {
      return jobtitles;
    } else {
      return jobtitles.filter((job) => {
        return job.jobTitle.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'jobBank'
})
export class JobBankPipe implements PipeTransform {

  transform(jobcodes: any, searchText: any): any {
    if(searchText == null) {
      return jobcodes;
    } else {
      return jobcodes.filter((job) => {
        return job.company.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}

@Pipe({
  name: 'jobstatus'
})
export class JobStatusPipe implements PipeTransform {

  transform(jobcodes: any, searchText: any): any {
    if(searchText == null) {
      return jobcodes;
    } else {
      return jobcodes.filter((job) => {
        return job.status.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      });
    }
  }
}
