
import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { JobcodeComponent } from './pages/jobcode/jobcode.component';
import { JobcodeCreateComponent } from './pages/jobcode-create/jobcode-create.component';
import { BewerbungenComponent } from './pages/bewerbungen/bewerbungen.component';
import { EinstellungenComponent } from './pages/einstellungen/einstellungen.component';
import { SupportComponent } from './pages/support/support.component';
import { BewerbungenDetailsComponent } from './pages/bewerbungen-details/bewerbungen-details.component';
import { ResetpasswordComponent } from './pages/resetpassword/resetpassword.component';
import { BankenVerwaltenComponent } from './admin/banken-verwalten/banken-verwalten.component';
import { BewerberVerwaltenComponent } from './admin/bewerber-verwalten/bewerber-verwalten.component';
import { BewerbungenVerwaltenComponent } from './admin/bewerbungen-verwalten/bewerbungen-verwalten.component';
import { JobcodesVerwaltenComponent } from './admin/jobcodes-verwalten/jobcodes-verwalten.component';
import { MailsComponent } from './admin/mails/mails.component';
import { CreatebankComponent } from './admin/createbank/createbank.component';
import { AuthGuard } from '../core/auth.guard';
import { BankDetailComponent } from './admin/bank-detail/bank-detail.component';
import { JobDetailComponent } from './admin/job-detail/job-detail.component';
import { PushComponent } from './admin/push/push.component';
import { EmailComponent } from './admin/email/email.component';
import { BewerberDetailComponent } from './admin/bewerber-detail/bewerber-detail.component'
 

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'resetpassword',
    component: ResetpasswordComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jobcode',
    component: JobcodeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jobcode/:jobId',
    component: JobcodeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'jobcode-create',
    component: JobcodeCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'bewerbungen',
    component: BewerbungenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bewerbungen-details',
    component: BewerbungenDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'einstellungen',
    component: EinstellungenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'banken-verwalten',
    component: BankenVerwaltenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'banken-verwalten/:pushId',
    component: BankDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bewerber-verwalten',
    component: BewerberVerwaltenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bewerber-verwalten/:userId',
    component: BewerberDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'bewerbungen-verwalten',
    component: BewerbungenVerwaltenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jobcode-admin',
    component: JobcodesVerwaltenComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jobcode-detail/:userId/:jobId',
    component: JobDetailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mail-vorlage',
    component: MailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'createbank',
    component: CreatebankComponent,
    canActivate: [AuthGuard]
  },  
  {
    path: 'push-msg',
    component: PushComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "email",
    component: EmailComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "email/:type",
    component: EmailComponent,
    canActivate: [AuthGuard]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
