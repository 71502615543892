import { Component, OnInit } from '@angular/core';
import { NoopAnimationPlayer } from '@angular/animations';
import { JobProviderService } from '../../providers/job-provider.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { UserProviderService } from 'src/app/providers/user-provider.service';
import { AuthService } from '../../providers/auth.service';
import { AngularFireDatabase } from 'angularfire2/database';
import { database } from 'firebase';

import { ToasterModule, ToasterService, ToasterConfig, Toast } from 'angular2-toaster';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

import { getOrCreateNodeInjector } from '@angular/core/src/render3/di';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';

declare var google: any;

@Component({
  selector: 'app-job-detail',
  templateUrl: './job-detail.component.html',
  styleUrls: ['./job-detail.component.less']
})
export class JobDetailComponent implements OnInit {

  question4Set: boolean;
  question5Set: boolean;
  question4 = '';
  question5 = '';
  status: string;
  private toasterService;
  form: FormGroup;
  public qcount: number;
  containers = [];
  jobNavPar;
  job: any;

  public config1: ToasterConfig = new ToasterConfig({
    positionClass: 'toast-top-full-width',
    newestOnTop: false,
    timeout: 3500,
    limit: 1,
    animation: 'slideUp'
  });

  constructor(
    public userProvider: UserProviderService,
    private jobProvider: JobProviderService,
    public afDatabase: AngularFireDatabase,
    public auth: AuthService,
    private fb: FormBuilder,
    private router: Router,
    public activeRoute: ActivatedRoute,
    private spinnerService: Ng4LoadingSpinnerService,
    toasterService: ToasterService,
    private gMapsApiLoader: MapsAPILoader

  ) {
    this.status = 'true';

    this.spinnerService.show();
    this.createForm().then(() => {
      this.qcount = 3;
      this.toasterService = toasterService;
      this.activeRoute.params.subscribe(param => {
        this.jobNavPar = param.jobId;
        if (this.jobNavPar != "" && this.jobNavPar) {
          this.jobProvider.getJobWithId(param).then((job: any) => {
            this.job = job;
            if (job.question4) {
              this.qcount = 4;
            }
            if (job.question5) {
              this.qcount = 5;
            }
            this.status = this.job.status;
            this.form.patchValue({ status: this.status });
            this.spinnerService.hide();
          })
            .catch((err) => {
              console.log(err.message);
              this.spinnerService.hide();
              if (err instanceof TypeError) {
                let toast: Toast = {
                  type: 'error',
                  title: 'Etwas ist schiefgelaufen. Bitte versuchen sie es nocheinmal.',
                  showCloseButton: true
                }
                this.toasterService.pop(toast);
                setTimeout(() => {
                  this.router.navigate(['/dashboard']);
                }, 2000);
              }
            })
        }
      });
    });
  }

  ngOnInit() {
  }

  /**
   * addQuestion() - adds a Question in Frontend
   */
  addQuestion() {
    this.qcount = this.qcount + 1;
    if (this.qcount <= 5) {
      if (this.qcount == 4) {
        this.question4Set = true;
      }
      if (this.qcount == 5) {
        this.question5Set = true;
      }
      this.containers.push(this.qcount);
    }
  }

  /**
   * createForm() creates a Form in frontend
   * @returns true if succesfully created the Form
   */
  createForm() {
    return new Promise<any>((resolve, reject) => {
      this.form = new FormGroup({
        jobTitle: new FormControl(),
        description: new FormControl(),
        plz: new FormControl(),
        city: new FormControl(),
        country: new FormControl(),
        aufrufe: new FormControl(),
        bewerbungen: new FormControl(),
        status: new FormControl(),
        question1: new FormControl(),
        question2: new FormControl(),
        question3: new FormControl(),
        question4: new FormControl(),
        question5: new FormControl(),
        userId: new FormControl(),
        jobId: new FormControl(),
        company: new FormControl(),
      });
      this.form = this.fb.group({
        jobTitle: ['', Validators.required],
        description: ['', Validators.required],
        plz: ['', Validators.required],
        city: ['', Validators.required],
        country: ['Deutschland', Validators.required],
        aufrufe: ['0', Validators.required],
        bewerbungen: ['0', Validators.required],
        status: [this.status, Validators.required],
        question1: ['', Validators.required],
        question2: ['', Validators.required],
        question3: ['', Validators.required],
        question4: ['', Validators.required],
        question5: ['', Validators.required],
        userId: ['', Validators.required],
        jobId: ['', Validators.required],
        company: ['', Validators.required]
      });
      resolve();
    });
  }

  /**
   * createJob(existjobId) creates or updates a job
   * @param existjobId if existant the existing job gets updated, if null this method will create a new Job
   */
  createJob(existjobId: any) {
    if (existjobId) { // create updateobject
      this.spinnerService.show();
      let { jobTitle, description, plz, city, country,
        question1, question2, question3, question4, question5, userId, jobId, status }
        = this.form.value;
      let updateObject: any = {};
      console.log('plz: ', plz);
      // Bewerbungen und Aufrufe werden nicht geändert
      if (!userId) {
        userId = this.job.userId;
      }
      if (jobTitle) {
        updateObject.jobTitle = jobTitle;
      }
      if (description) {
        updateObject.description = description;
      }
      if (plz) {
        updateObject.plz = plz;
      }
      if (city) {
        updateObject.city = city;
      }
      if (country) {
        updateObject.country = country;
      }
      if (this.job.status != status) { 
        updateObject.status = status;
        //this.jobProvider.updateJobCount(userId, status);
      }
      if (question1) {
        updateObject.question1 = question1;
      }
      if (question2) {
        updateObject.question2 = question2;
      }
      if (question3) {
        updateObject.question3 = question3
      }
      if (question4) {
        updateObject.question4 = question4;
      }
      if (question5) {
        updateObject.question5 = question5;
      }
      if (updateObject && Object.keys(updateObject).length != 0) {
        //21.11.19: removed country cause its a fixed value.
        if (updateObject.plz || updateObject.city) {
          this.getLatLng(plz + " " + city + ", " + country).then((response: { lat: string, lng: string }) => {
            if (response.lat == "51.165691" && response.lng == "10.451526000000058") {
              this.spinnerService.hide();
              let toast: Toast = {
                type: 'info',
                title: 'Wir konnten die Adresse nicht ermitteln, bitte prüfen sie Plz und Ort.',
                showCloseButton: true
              }
              this.toasterService.pop(toast);
            } else {
              updateObject.lat = response.lat;
              updateObject.lng = response.lng;
              this.jobProvider.updateJob(updateObject, userId, existjobId).then(() => {
                this.jobProvider.updateJobCount(userId, status).then(() => {
                  let toast: Toast = {
                    type: 'success',
                    title: 'Jobcode erfolgreich geändert!',
                    showCloseButton: true
                  }
                  this.spinnerService.hide();
                  this.toasterService.pop(toast);
                  this.recreateFormData(existjobId);
                });
              }).catch((err) => {
                this.spinnerService.hide();
                console.log(err);
              });
            }
          }).catch((err) => {
            this.spinnerService.hide();
            console.log(err);
          });
        } else {
          this.jobProvider.updateJob(updateObject, userId, existjobId).then(() => {
            this.jobProvider.updateJobCount(userId, status).then(() => {
              let toast: Toast = {
                type: 'success',
                title: 'Jobcode erfolgreich geändert!',
                showCloseButton: true
              }
              this.spinnerService.hide();
              this.toasterService.pop(toast);
              this.recreateFormData(existjobId);
            });
          }).catch((err) => {
            this.spinnerService.hide();
            console.log(err);
          });
        }
      }
      else {
        let toast: Toast = {
          type: 'info',
          title: 'Sie haben keine Änderungen vorgenommen.',
          showCloseButton: true
        }
        this.spinnerService.hide();
        this.toasterService.pop(toast);
      }
    }
    //etwas ist schiefgegangen
    else {
      let toast: Toast = {
        type: 'info',
        title: 'Etwas ist schiefgegangen. Bitte versuchen Sie es erneut.',
        showCloseButton: true
      }
      this.spinnerService.hide();
      this.toasterService.pop(toast);
    }
  }

  /**
   * calculates the lat long of a given address
   * @param address the adress consistent of city plz and country
   */
  getLatLng(address) {
    return new Promise((resolve, reject) => {
      this.gMapsApiLoader.load().then(() => {
        let geocode = new google.maps.Geocoder();
        geocode.geocode({ 'address': address }, (results, status) => {
          if (status == google.maps.GeocoderStatus.OK) {
            console.log('Success - ', results);
            //get lat lng from results and resolve it
            let lat = results[0].geometry.location.lat();
            let lng = results[0].geometry.location.lng();
            resolve({ lat, lng });
          } else {
            console.log('Error - ', results, ' & Status - ', status);
            resolve(null);
          }
        });
      });
    });
  }


  /**
   * delQuestion() deletes a question in frontend
   * @param index the index of the question that gets deleted
   */
  delQuestion(index) {
    console.log("index ", index);
    if (this.qcount > 3) {
      if (this.qcount == 4) {
        this.question4Set = false;
        this.form.patchValue({ question4: '' });
      } else {
        // qcount = 5 | frage 4 löschen -> frage4 = frage 5 -> frage 5 löschen
        if (index == 0) {
          let a = this.form.get('question5');
          let b = a.value
          this.form.patchValue({ question4: b });
          this.form.patchValue({ question5: '' })
        }
        if (index == 1) {
          this.question5Set = false;
          this.form.patchValue({ question5: '' });
        }
      }
      this.containers.pop();
      this.qcount = this.qcount - 1;
    }
  }

  /**
   * deletes q4 or q5 if there and swaps them around if q4 is deleted and q5 is set (q4 = q5, q5 = null)
   * @param jobId the jobId provided to delete the question from backend
   * @param question the question to delete
   */
  delQuestNr(jobId, question) {
    if (window.confirm('Frage wirklich löschen?')) {
      this.jobProvider.delQuestNr(jobId, question).then(() => {
        if (this.job.question4 == question) {
          if (this.job.question5) {
            this.job.question4 = this.job.question5;
            this.job.question5 = null;
            this.qcount = 4;
          } else {
            this.job.question4 = null;
            this.qcount = 3;
            console.log(this.qcount)
          }
        }
        if (this.job.question5 == question) {
          this.job.question5 = null;
          this.qcount = 4;
        }
        let toast: Toast = {
          type: 'success',
          title: 'Frage gelöscht!',
          showCloseButton: true
        }
        this.toasterService.pop(toast);
      });
    }
  }

  /**
   * creates a random id for a job
   * @returns the created id
   */
  makeId() {
    let text = "";
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < 5; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  /**
   * recreates the jobData with DB values
   * @param pushId the push id of the searcged job
   */
  recreateFormData(pushId) {
    console.log(pushId, ' <-job of user -> ', this.job.userId);
    let adminGetJob = {
      jobId: pushId,
      userId: this.job.userId
    }
    this.jobProvider.getJobWithId(adminGetJob).then((job: any) => {
      console.log(job);
      this.job = job;
      this.status = job.status;
      this.form.patchValue({ status: job.status });
      if (job.question4 && job.question4 != '') {
        this.question4 = job.question4;
      }
      if (job.question5 && job.question5 != '') {
        this.question5 = job.question5;
      }
      this.updateForm();
      this.spinnerService.hide();
    })
  }

  /**
   * sets the question count
   */
  setCount() {
    this.qcount += 1;
    console.log(this.qcount);
  }

  /**
   * updates the formData
   */
  updateForm() {
    this.form = this.fb.group({
      jobTitle: [this.job.jobTitle, Validators.required],
      description: [this.job.description, Validators.required],
      plz: [this.job.plz, Validators.required],
      city: [this.job.city, Validators.required],
      country: [this.job.country, Validators.required],
      aufrufe: ['0', Validators.required],
      bewerbungen: ['0', Validators.required],
      status: [this.job.status, Validators.required],
      question1: [this.job.question1, Validators.required],
      question2: [this.job.question2, Validators.required],
      question3: [this.job.question3, Validators.required],
      question4: [this.question4, Validators.required],
      question5: [this.question5, Validators.required],
      userId: [this.job.userId, Validators.required],
      jobId: [this.job.jobId, Validators.required],
      company: [this.job.company, Validators.required]
    });
  }

}
