import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { UserProviderService } from 'src/app/providers/user-provider.service';
import { JobProviderService } from 'src/app/providers/job-provider.service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataproviderService } from '../../admin/provider/dataprovider.service';
import { AngularFireDatabase } from 'angularfire2/database';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

  jobs: any;
  user: any;
  admin: any;
  jobCount: number = 0;
  jobActive: number = 0;
  jobInactive: number = 0;
  account: any;
  applicationCount: number = 0;
  applicantCount: number = 0;
  aufrufe: number = 0;

  constructor(
    public userProvider: UserProviderService,
    private auth: AuthService,
    private router: Router,
    public spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataproviderService,
    private afDatabase: AngularFireDatabase,
    private jobService: JobProviderService
  ) {
    //check multiuser 
    console.log('mutliuserCheck: ', this.auth.adminUser);
  }

  ngOnInit() {
    this.getData();
    this.spinnerService.show();
  }

  getData() {
    this.auth.checkUserState().then(res => {
      if(res){
        console.log('2nd level user found');
      }
    });
    this.getAdmin(); //admin
    this.getUserData(); // all
    this.getAccountData(); //admin
    this.getapplications(); //all
    this.getCounts(); //admin
    this.getBewerber(); //all
  }

  getAccountData() {
    this.dataService.getAccountData().then((acc) => {
      this.account = acc;
    });
  }

  getAdmin() {
    this.dataService.getAdmin().then((admin) => {
      this.admin = admin;
      console.log('admin', admin);
      this.spinnerService.hide();
    });
  }

  getapplications(){
    this.userProvider.getApplications().catch((err) => {
      console.log(err);
    });
    this.dataService.getBewerbungenAdmin().then((applications) => {
      // console.log('(): ', applications);
      let count = 0;
      let keys = Object.keys(applications);
      keys.forEach((key) => {
        // console.log('applicationId: ', key);
        count++;
      });
      this.applicationCount = count;
    }).catch((err) => {
      console.log(err);
    });
  }

  getBewerber(){
    this.dataService.getBewerber().then((bewerber) => {
      // console.log(bewerber);
      let keys = Object.keys(bewerber);
      let count = 0;
      keys.forEach((key) => {
        count++;
      });
      this.applicantCount = count;
    });
  }

  
  getCounts() {
    this.jobService.getJobCount().then((count) => {
      this.jobActive = count[0];
      this.jobInactive = count[1];
      this.jobService.getAllJobs().then((jobs) => {
        if(jobs){
          // console.log(jobs);
          let aufrufe = 0;
          let keys = Object.keys(jobs);
          keys.forEach((job) => {
            if(jobs[job].aufrufe > 0){
              aufrufe += jobs[job].aufrufe;
            }
          });
          this.aufrufe = aufrufe;
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  }

  getUserData() {
    this.userProvider.getUserData().then((user) => {
      this.user = user;
      if(!this.admin){
        if(!this.user['applications']){
          this.user.applications = 0;
        }
      }

      this.spinnerService.hide();
    });
  }

  toJob() {
    this.router.navigate(['/jobcode-create']);
  }

  toJobs() {
    this.router.navigate(['/jobcode']);
  }

  toApplication() {
    this.router.navigate(['/bewerbungen']);
  }

  toSettings() {
    this.router.navigate(['/einstellungen']);
  }

}
